/* eslint-disable @typescript-eslint/no-explicit-any */
import Compressor from 'compressorjs'
import { commonUtils } from './commonUtils'
import { message } from 'antd'
import { Upload } from './api/upload'
import axios, { AxiosProgressEvent } from 'axios';


interface UploadFileData {
  userId: Id
  file: File
  module: PostData.FileUploadModule
  visible?: boolean
  progress?: (p: number) => void
  /**
   * 图片压缩参数, maxFileSize: 图片压缩的最大文件大小
  */
  compressImage?: {
    maxFileSize: number
  }
  compressImageSize?: {
    width: number
    height: number
  }

}

class UploadService {
  public async uploadFile(data: UploadFileData): Promise<{ cdnUrl: string; fileId: Id }> {
    if (commonUtils.checkUploadFileType(data.file)) {
      message.warning(commonUtils.checkUploadFileType(data.file))
      return Promise.reject({ fileStatus: commonUtils.checkUploadFileType(data.file) })
    }

    // 处理图片压缩
    if (data.compressImage) {
      data.file = await this.compressImageByFileSize(data.file, data.compressImage.maxFileSize)
    }

    if (String(data.userId).indexOf('@') > -1) {
      /** ‘@’ 会在转义的时候出现问题 */
      data.userId = String(data.userId).replace(/@/g, 'a')
    }
    return this.uploadFileToCos(data)

  }
  /**
   * 图片压缩方法
   * @param {Object}  file 图片文件
   * @param {String} type 想压缩成的文件类型
   * @param {Nubmber} quality 压缩质量参数
   * @returns 压缩后的新图片
   */
  private compressImage(data: { file: File; quality: number }): Promise<File> {
    const { file, quality } = data
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality,
        success(result: any) {
          resolve(result as File)
        },
        error(err: any) {
          reject(err)
          // console.log('图片压缩失败', err.message)
        },
      })
    })
  }

  /** 压缩图片工具函数
   *  当图片大于传入的maxFileSize时，压缩图片，将其压缩到5M以下，压缩比例最高为0.8，最低为0.2
   *  @param {File} file
   *  @param {Number} maxFileSize
   */
  private async compressImageByFileSize(file: File, maxFileSize: number = 5 * 1024 * 1024) {
    // 压缩20M以下的图片，将图片的值维持在maxFileSize以下，声明变量quality为当前压缩比例，其中quality的最大值为0.8，最小值为0.2，比例随着file.size的增大而减小
    let quality = maxFileSize / (file.size as number) > 0.8 ? 0.8 : maxFileSize / (file.size as number)
    quality = quality < 0.2 ? 0.2 : quality
    const newFile = await this.compressImage({
      file,
      quality,
    })
    return newFile
  }


  private async uploadFileToCos(data: UploadFileData): Promise<{ cdnUrl: string, fileId: Id }> {
    const { file, progress } = data

    try {
      const {
        data: { fileId, signUrl },
      } = await Upload.getUploadSignUrl({
        contentLength: file.size,
        filename: file.name,
        module: data.module,
      })
      await axios.put(signUrl, file, {
        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / 100);
          progress?.(percentCompleted)
        }
      })

      await Upload.updateUploadStatus({ fileId })
      const cdnUrl = await Upload.getFileUrl(fileId)
      return Promise.resolve({ cdnUrl: cdnUrl.downloadUrl, fileId })
    } catch (error) {
      return Promise.reject(error)
    }
  }

}

export const uploadService = new UploadService()
