import { AttachmentsSelectionDialogProps } from '@/components/AttachmentsSelectionDialog';
import { GroupNestDetailDrawerProps } from '@/components/GroupNestDetailDrawer';
import { SelectUserDialogProps } from '@/components/SelectUserDialog';
import { UploadExcelDialogProps } from '@/components/UploadExcelDialog';
import { ModalProps } from 'antd';
import { makeObservable, observable } from 'mobx';
import { SelectGroupNestDialogProps } from '@/components/SelectGroupNestDialog';
import { SelectCouponDialogProps } from '@/components/SelectCouponDialog';
import { AutoSendDetailDrawerProps } from '@/components/AutoSendDetailDrawer/AutoSendDetailDrawer';
import { JoinGroupGiftDetailDrawerProps } from '@/components/JoinGroupGiftDetailDrawer/JoinGroupGiftDetailDrawer';

interface CommonModalProps extends ModalProps {
  content?: string;
}
class UiController {
  @observable commonModalProps: CommonModalProps = { open: false };

  @observable groupNestDetailDrawerProps: GroupNestDetailDrawerProps = {
    open: false,
    data: { groupNest:undefined },
  };

  @observable selectUserDialogProps: SelectUserDialogProps = {
    open: false,
    data: { title: '' },
  }

  @observable uploadExcelDialogProps: UploadExcelDialogProps = { open: false };

  @observable attachmentsSelectionDialogProps: AttachmentsSelectionDialogProps = { open: false };

  @observable selectGroupNestDialogProps: SelectGroupNestDialogProps = {
    open: false,
    data: { title: '' },
  };

  @observable selectCouponDialogProps: SelectCouponDialogProps = {
    open: false,
    data: { title: '' },
  };

  @observable autoSendDetailDrawerProps: AutoSendDetailDrawerProps = {
    open: false,
    data: { task: undefined },
  };

  @observable joinGroupGiftDetailDrawerProps: JoinGroupGiftDetailDrawerProps = {
    open: false,
    data: { giftDetail: undefined },
  };

  public init() {
    makeObservable(this);
  }

  public async openGroupDetailDrawer(
    groupDetailDrawerProps: GroupNestDetailDrawerProps,
  ) {
    this.groupNestDetailDrawerProps = groupDetailDrawerProps;
  }

  public async openSelectUserDialog(selectUserDialogProps: SelectUserDialogProps) {
    this.selectUserDialogProps = selectUserDialogProps;
  }

  public async openUploadExcelDialog(props: UploadExcelDialogProps) {
    this.uploadExcelDialogProps = {...props, open: true};
  }

  public async openAttachmentsSelectionDialog(props: AttachmentsSelectionDialogProps) {
    this.attachmentsSelectionDialogProps = {...props, open: true};
  }

  public async openSelectGroupNestDialog(selectGroupNestDialogProps: SelectGroupNestDialogProps) {
    this.selectGroupNestDialogProps = selectGroupNestDialogProps;
  }

  public async openSelectCouponDialog(selectCouponDialogProps: SelectCouponDialogProps) {
    this.selectCouponDialogProps = selectCouponDialogProps;
  }

  public async openAutoSendDetailDrawer(
    autoSendDetailDrawerProps: AutoSendDetailDrawerProps,
  ) {
    this.autoSendDetailDrawerProps = autoSendDetailDrawerProps;
  }

  public async openJoinGroupGiftDetailDrawer(
    joinGroupGiftDetailDrawerProps: JoinGroupGiftDetailDrawerProps,
  ) {
    this.joinGroupGiftDetailDrawerProps = joinGroupGiftDetailDrawerProps;
  }
}

export const uiController = new UiController();
