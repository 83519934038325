import { uiController } from '@/controllers/uiController';
import { observer } from 'mobx-react';
import { FC } from 'react';
import AttachmentsSelectionDialog from '../AttachmentsSelectionDialog/AttachmentsSelectionDialog';
import AutoSendDetailDrawer from '../AutoSendDetailDrawer/AutoSendDetailDrawer';
import GroupNestDetailDrawer from '../GroupNestDetailDrawer/GroupNestDetailDrawer';
import JoinGroupGiftDetailDrawer from '../JoinGroupGiftDetailDrawer/JoinGroupGiftDetailDrawer';
import SelectCouponDialog from '../SelectCouponDialog/SelectCouponDialog';
import SelectGroupNestDialog from '../SelectGroupNestDialog/SelectGroupNestDialog';
import SelectUserDialog from '../SelectUserDialog/SelectUserDialog';
import UploadExcelDialog from '../UploadExcelDialog/UploadExcelDialog';

const CommonDialogLayout: FC = observer(() => {
  return (
    <div>
      {/* 群组详情弹窗,这里不一定需要公共弹窗，目前是为了跑通公共弹窗实现先写在这里 */}
      {uiController.groupNestDetailDrawerProps.open && (
        <GroupNestDetailDrawer
          open={uiController.groupNestDetailDrawerProps.open}
          data={uiController.groupNestDetailDrawerProps.data}
        />
      )}
      {/* 选择成员弹窗 */}
      {uiController.selectUserDialogProps.open && (
        <SelectUserDialog
          open={uiController.selectUserDialogProps.open}
          data={uiController.selectUserDialogProps.data}
        />
      )}
      {/* 上传Excel */}
      {uiController.uploadExcelDialogProps.open && (
        <UploadExcelDialog
          open={uiController.uploadExcelDialogProps.open}
          data={uiController.uploadExcelDialogProps.data}
        />
      )}
      {/* 选择附件 */}
      {uiController.attachmentsSelectionDialogProps.open && (
        <AttachmentsSelectionDialog
          open={uiController.attachmentsSelectionDialogProps.open}
          data={uiController.attachmentsSelectionDialogProps.data}
        />
      )}
      {/* 选择群组弹窗 */}
      {uiController.selectGroupNestDialogProps.open && (
        <SelectGroupNestDialog
          open={uiController.selectGroupNestDialogProps.open}
          data={uiController.selectGroupNestDialogProps.data}
        />
      )}
      {/* 选择优惠券弹窗 */}
      {uiController.selectCouponDialogProps.open && (
        <SelectCouponDialog
          open={uiController.selectCouponDialogProps.open}
          data={uiController.selectCouponDialogProps.data}
        />
      )}
      {/* 自动群发详情弹窗 */}
      {uiController.autoSendDetailDrawerProps.open && (
        <AutoSendDetailDrawer
          open={uiController.autoSendDetailDrawerProps.open}
          data={uiController.autoSendDetailDrawerProps.data}
        />
      )}
      {/* 入群有礼详情弹窗 */}
      {uiController.joinGroupGiftDetailDrawerProps.open && (
        <JoinGroupGiftDetailDrawer
          open={uiController.joinGroupGiftDetailDrawerProps.open}
          data={uiController.joinGroupGiftDetailDrawerProps.data}
        />
      )}
    </div>
  );
});

export default CommonDialogLayout;
