import { uiController } from '@/controllers/uiController';
import { Form, Radio } from 'antd';
import { observer } from 'mobx-react';
import { FC, useEffect, useState } from 'react';
import AmeInput from '../AmeInput/AmeInput';
import Dialog from '../Dialog/Dialog';
import UploadFileBox from '../UploadFileBox/UploadFileBox';
import styles from './AttachmentsSelectionDialog.module.less';

export interface AttachmentsSelectionDialogProps {
  open: boolean;
  data?: {
    module: PostData.FileUploadModule;
    attachment?: Models.Message.MessageAttachment;
    onOk?: (attachment: Models.Message.MessageAttachment) => void;
  };
}

const AttachmentsSelectionDialog: FC<AttachmentsSelectionDialogProps> = (props: AttachmentsSelectionDialogProps) => {
  const [visible, setVisible] = useState(props.open);
  const [form] = Form.useForm();
  const [messageType, setMessageType] = useState<Models.Message.MessageType>('image-text'); // 控制Tab切换
  const { setFieldsValue, setFieldValue } = form;
  const [imageUrl, setImageUrl] = useState<string>('');

  // 初始化表单
  useEffect(() => {
    if (props.data?.attachment) {
      const { msgType, imageTextMsgContent, tinyAppMsgContent } = props.data?.attachment;

      let title = '';
      let description = '';
      let appId: Id = '';
      let link = '';
      let imageUrl = '';
      let miniAppLink = '';
      if (imageTextMsgContent) {
        title = imageTextMsgContent.title;
        description = imageTextMsgContent.desc;
        link = imageTextMsgContent.url;
        imageUrl = imageTextMsgContent.imageUrl;
        setImageUrl(imageUrl);
      }

      if (tinyAppMsgContent) {
        title = tinyAppMsgContent.title;
        description = tinyAppMsgContent.desc;
        appId = tinyAppMsgContent.tinyAppId;
        miniAppLink = tinyAppMsgContent.url;
        imageUrl = tinyAppMsgContent.imageUrl;
        setImageUrl(imageUrl);
      }

      setMessageType(msgType);

      setFieldsValue({
        messageType: msgType,
        title,
        description,
        appId,
        link,
        imageUrl,
        miniAppLink,
      });
    }
  }, [props.data?.attachment, setFieldsValue]);

  useEffect(() => {
    setFieldValue('imageUrl', imageUrl);
  }, [imageUrl, setFieldValue]);

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        const { messageType, title, description, appId, link, imageUrl, miniAppLink } = values;
        if (messageType === 'image-text') {
          const attachment: Models.Message.MessageAttachment = {
            msgType: messageType,
            imageTextMsgContent: {
              title,
              desc: description,
              url: link,
              imageUrl,
            },
          };
          props.data?.onOk?.(attachment);
        }

        if (messageType === 'tiny') {
          const attachment: Models.Message.MessageAttachment = {
            msgType: messageType,
            tinyAppMsgContent: {
              title,
              desc: description,
              tinyAppId: appId,
              imageUrl,
              url: miniAppLink,
            },
          };
          props.data?.onOk?.(attachment);
        }

        handleClose();
      })
      .catch((info) => {
        console.log('Validation Failed:', info);
      });
  };

  const handleClose = () => {
    setTimeout(() => {
      uiController.attachmentsSelectionDialogProps.open = false;
    }, 200);
    setVisible(false);
  };

  return (
    <Dialog
      title="卡片消息"
      open={visible}
      onOk={handleOk}
      onCancel={handleClose}
      okText="确定"
      cancelText="取消"
      className={styles['attachments-selection-dialog']}
    >
      <Form form={form} layout="horizontal" initialValues={{ messageType: 'image-text' }}>
        <Form.Item
          labelAlign="right"
          name="messageType"
          label="创群方式"
          rules={[{ required: true, message: '请选择创群方式' }]}
        >
          <Radio.Group onChange={(e) => setMessageType(e.target.value)}>
            <Radio value="image-text">图文消息</Radio>
            <Radio value="tiny">小程序消息</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item name="title" label="标题" rules={[{ required: true, message: '请输入标题' }]}>
          <AmeInput maxLength={23} placeholder="请输入标题" />
        </Form.Item>

        <Form.Item name="description" label="描述" rules={[{ required: true, message: '请输入描述' }]}>
          <AmeInput maxLength={45} placeholder="请输入描述" />
        </Form.Item>

        {messageType === 'tiny' && (
          <>
            <Form.Item name="appId" label="应用id" rules={[{ required: true, message: '请输入正确的小程序id' }]}>
              <AmeInput placeholder="请输入正确的小程序id" />
            </Form.Item>

            <Form.Item
              name="miniAppLink"
              label="跳转链接"
              rules={[
                {
                  required: true,
                  message: '请输入正确的小程序链接',
                  pattern: new RegExp(/^[^\s]+$/), // 正则校验链接
                },
              ]}
            >
              <AmeInput placeholder="请输入正确的小程序链接" />
            </Form.Item>
          </>
        )}

        {messageType === 'image-text' && (
          <Form.Item
            name="link"
            label="跳转链接"
            rules={[
              {
                required: true,
                message: '请输入有效的链接',
                pattern: new RegExp(/^(http|https|alipays):\/\/[^\s]+$/),
              },
            ]}
          >
            <AmeInput placeholder="请输入http、https或alipays开头的链接" />
          </Form.Item>
        )}

        <Form.Item name="imageUrl" label="图片" rules={[{ required: true, message: '请上传图片' }]}>
          <UploadFileBox
            type="image"
            maxCount={1}
            selectedImage={imageUrl}
            // 这里的data其实应该必定存在
            module={props.data!.module}
            maxSize={1}
            tips="支持的图片jpg、png、大小 400*200，限制1M以内"
            onOk={(fileList) => {
              const newImageUrl = fileList.length ? fileList[0].fileUrl : undefined;
              setFieldValue('imageUrl', newImageUrl);
            }}
          />
        </Form.Item>
      </Form>
    </Dialog>
  );
};

export default observer(AttachmentsSelectionDialog);
