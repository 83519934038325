import EasyQrCode from 'easyqrcodejs';
// import QRCode from 'qrcode'

export interface qrcodeOptions {
  // 二维码内容
  content: string;
  // 图标
  logo?: File;
  // 二维码宽度
  width?: number;
}

export function generateCustomQrcode(options: qrcodeOptions): Promise<string> {
  const { width = 500 } = options;
  return new Promise(async (resolve, reject) => {
    const easyCodeOptions = {
      text: options.content,
      correctLevel: EasyQrCode.CorrectLevel.M, // L, M, Q, H
      width,
      height: width,
      // logoWidth: width / 3.5, // fixed logo width. default is `width/3.5`
      // logoHeight: width / 3.5, // fixed logo height. default is `heigth/3.5`
      logo: '', // logo || INFINITE_GROUP_DEFAULT_AVATAR,
      // 二维码点设置
      dotScale: 0.5, // For body block, must be greater than 0, less than or equal to 1. default is 1
      dotScaleTiming: 0.5,
      dotScaleTiming_H: 0.5,
      dotScaleTiming_V: 0.5,
      dotScaleA: 0.4,
      // dotScaleAO: 0.4,
      // dotScaleAI: 0.4,
      quietZone: 2,
      // quietZoneColor: 'rgba(0,0,0,0)',
    };
    const easyQrCodeEl = document.createElement('div');
    easyQrCodeEl.style.display = 'none';
    document.body.appendChild(easyQrCodeEl);
    const easyQrCode = new EasyQrCode(easyQrCodeEl, easyCodeOptions);
    const easyQrCodeCanvas = easyQrCodeEl.querySelector('canvas') as HTMLCanvasElement;

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = width;
    canvas.height = width;
    const img = document.createElement('img');
    img.onload = () => {
      if (ctx) {
        ctx.drawImage(img, 0, 0);
        if (options.logo) {
          const logoBgWidth = Math.floor(width / 4);
          const logoBgOffset = Math.floor((width * 3) / 8);
          let logoOffset = Math.floor(logoBgWidth / 20);
          const logoWidth = logoBgWidth - logoOffset * 2;
          ctx.fillStyle = '#ffffff';
          ctx.fillRect(logoBgOffset, logoBgOffset, logoBgWidth, logoBgWidth);
          const logoImg = document.createElement('img');
          logoImg.onload = () => {
            logoOffset = logoBgOffset + logoOffset;
            if (ctx) {
              ctx.drawImage(logoImg, logoOffset, logoOffset, logoWidth, logoWidth);
            }
            resolve(canvas.toDataURL());
          };
          if (options.logo) {
            const reader = new FileReader();
            reader.onload = () => {
              logoImg.src = reader.result as string;
            };
            reader.readAsDataURL(options.logo);
          }
        } else {
          resolve(canvas.toDataURL());
        }
      }
    };
    img.onerror = (e) => {
      reject(e);
    };
    // img.src = qrcode
    if (easyQrCode && easyQrCodeCanvas) {
      img.src = easyQrCodeCanvas.toDataURL('image/jpeg', 1.0);
    }
  });
}
