/* eslint-disable @typescript-eslint/no-unused-vars */
import { CommonApi } from '@/helpers/api/commonApi';
import { commonUtils } from '@/helpers/commonUtils';
import { routerService } from '@/services/routerService';
import { RouterServiceTypes } from '@/services/routerService/serviceTypes';
import { Button, Spin } from 'antd';
import { observer } from 'mobx-react';
import { FC, useEffect, useState } from 'react';
import styles from './GuideComponent.module.less';

interface GuideComponentProps {
  children: React.ReactNode;
}

type PageStatus = 'loading' | 'guide' | 'main';

const FeatureData: {
  [key in RouterServiceTypes.PageType]?: {
    title: string;
    mainImage: string;
    mainBtn: {
      text: string;
      onClick: () => void;
    };
    subList: { title: string; desc: string }[];
  };
} = {
  autoSendManagePage: {
    title: '自动群发',
    mainImage: commonUtils.getStaticFile('guide/main-img-autoSendManagePage.png'),
    mainBtn: {
      text: '创建群发',
      onClick: () => {
        routerService.navigateTo('autoSendCreatePage');
      },
    },
    // 底部介绍列表
    subList: [
      {
        title: '每日优惠推送',
        desc: '商家可每日借助此功能向支付宝粉丝群推送优惠券，快速吸引粉丝关注并刺激消费，提升店铺销售活力',
      },
      {
        title: '营销活动触达',
        desc: '在重要营销节点，迅速将活动信息传达至粉丝群，吸引粉丝积极参与，实现销售爆发',
      },
      {
        title: '周期性活动运营',
        desc: '通过规划周期性群活动，如抽奖、会员福利发放等，以用户转化为目标，保持粉丝群活跃度，培养粉丝消费习惯',
      },
    ],
  },
};

// type转译
const typeToPageKey = (type: RouterServiceTypes.PageType): Models.featureType | undefined => {
  switch (type) {
    case 'autoSendManagePage':
      return 'group_msg';
    case 'couponManagePage':
      return 'coupon_activity';
    default:
      return undefined;
  }
};

const GuideComponent: FC<GuideComponentProps> = observer((props: GuideComponentProps) => {
  const [pageStatus, setPageStatus] = useState<PageStatus>('loading');
  const currentPageKey = routerService.getCurrentPageKey();

  useEffect(() => {
    if (typeToPageKey(currentPageKey) && FeatureData[currentPageKey]) {
      CommonApi.getFeatureConfigStatus({
        featureName: typeToPageKey(currentPageKey) as Models.featureType,
      }).then((res) => {
        if (res.fcStatus === 'enable') {
          setPageStatus('main');
        } else {
          setPageStatus('guide');
        }
      });
    } else {
      setPageStatus('main');
    }
  }, [currentPageKey]);

  return (
    <div className={styles.guideComponent}>
      {pageStatus === 'loading' && (
        <div className={styles.loading}>
          <Spin />
        </div>
      )}
      {pageStatus === 'guide' && FeatureData[currentPageKey] && (
        <div className={styles.guide}>
          <p className={styles.title}>{FeatureData[currentPageKey]?.title}</p>
          <img className={styles.mainImage} src={FeatureData[currentPageKey]?.mainImage} alt="" />
          <Button
            className={styles.mainBtn}
            type="primary"
            onClick={() => {
              CommonApi.openFeatureConfig({
                featureName: typeToPageKey(currentPageKey) as Models.featureType,
              }).then(() => {
                FeatureData[currentPageKey]?.mainBtn.onClick();
              });
            }}
          >
            {FeatureData[currentPageKey]?.mainBtn.text}
          </Button>
          <div className={styles.subList}>
            {FeatureData[currentPageKey]?.subList.map((item, index) => (
              <div key={index} className={styles.subItem}>
                <p className={styles.subItemTitle}>
                  {item.title}
                  <img className={styles.subItemBg} src={commonUtils.getStaticFile('guide/sub-item-bg.png')} alt="" />
                </p>
                <p className={styles.subItemDesc}>{item.desc}</p>
              </div>
            ))}
          </div>
        </div>
      )}
      {pageStatus === 'main' && props.children}
    </div>
  );
});

export default GuideComponent;
