/* eslint-disable react/display-name */
import { ElementType, Suspense, lazy } from 'react';
import { RouteObject, useRoutes } from 'react-router-dom';
import AmeSpin from './components/AmeSpin/AmeSpin';
import { PAGE_LINKS } from './helpers/commonConsts';

const Loadable = (Component: ElementType) => (props: any) => (
  <Suspense fallback={<AmeSpin fullscreen={true} />}>
    <Component {...props} />
  </Suspense>
);

const HomePage = Loadable(lazy(() => import('./pages/HomePage/HomePage')));
const LoginPage = Loadable(lazy(() => import('./pages/LoginPage/LoginPage')));
// 自动拉群
const InviteGroupManagePage = Loadable(lazy(() => import('./pages/InviteGroupManagePage/InviteGroupManagePage')));
const InviteGroupCreatePage = Loadable(lazy(() => import('./pages/InviteGroupCreatePage/InviteGroupCreatePage')));
// 自动群发
const AutoSendManagePage = Loadable(lazy(() => import('./pages/AutoSendManagePage/AutoSendManagePage')));
const AutoSendCreatePage = Loadable(lazy(() => import('./pages/AutoSendCreatePage/AutoSendCreatePage')));
// 群公告
const GroupNoticeManagePage = Loadable(lazy(() => import('./pages/GroupNoticeManagePage/GroupNoticeManagePage')));
const GroupNoticeCreatePage = Loadable(lazy(() => import('./pages/GroupNoticeCreatePage/GroupNoticeCreatePage')));
const NoFoundPage = Loadable(lazy(() => import('./pages/NoFoundPage/NoFoundPage')));

// 优惠券
const CouponManagePage = Loadable(lazy(() => import('./pages/CouponManagePage/CouponManagePage')));
// 成员管理
const StaffManagePage = Loadable(lazy(() => import('./pages/StaffManagePage/StaffManagePage')));

// 入群有礼
const JoinGroupGiftManagePage = Loadable(lazy(() => import('./pages/JoinGroupGiftManagePage/JoinGroupGiftManagePage')));
const JoinGroupGiftCreatePage = Loadable(lazy(() => import('./pages/JoinGroupGiftCreatePage/JoinGroupGiftCreatePage')));

const routes: RouteObject[] = [
  {
    path: PAGE_LINKS.index,
    element: <HomePage />,
  },
  {
    path: PAGE_LINKS.home,
    element: <HomePage />,
  },
  {
    path: PAGE_LINKS.login,
    element: <LoginPage />,
  },
  // 入群有礼管理页面
  {
    path: PAGE_LINKS.joinGroupGiftManagePage,
    element: <JoinGroupGiftManagePage />,
  },
  // 入群有礼创建页面
  {
    path: PAGE_LINKS.joinGroupGiftCreatePage,
    element: <JoinGroupGiftCreatePage />,
  },
  // 自动拉群管理页面
  {
    path: PAGE_LINKS.inviteGroupManage,
    element: <InviteGroupManagePage />,
  },
  // 自动拉群创建页面
  {
    path: PAGE_LINKS.inviteGroupCreate,
    element: <InviteGroupCreatePage />,
  },
  // 自动群发管理页面
  {
    path: PAGE_LINKS.autoSendManagePage,
    element: <AutoSendManagePage />,
  },
  // 自动群发创建页面
  {
    path: PAGE_LINKS.autoSendCreatePage,
    element: <AutoSendCreatePage />,
  },
  // 群公告管理页面
  {
    path: PAGE_LINKS.groupNoticeManagePage,
    element: <GroupNoticeManagePage />,
  },
  // 群公告创建页面
  {
    path: PAGE_LINKS.groupNoticeCreatePage,
    element: <GroupNoticeCreatePage />,
  },
  // 优惠券管理页面
  {
    path: PAGE_LINKS.couponManagePage,
    element: <CouponManagePage />,
  },
  // 账号管理
  {
    path: PAGE_LINKS.staffManagePage,
    element: <StaffManagePage />,
  },
  // 404页面
  {
    path: '*',
    element: <NoFoundPage />,
  },
];

export const Router = () => {
  return useRoutes(routes);
};
