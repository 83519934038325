import { get, post } from './common';

export namespace GroupApi {
  // 获取群组列表
  export const getGroupNestList = (params: { pageNo: number; pageSize: number; keyword?: string }) => {
    return get<{ hasNext: boolean; objects: Models.Group.GroupNest[]; totalCount: number }>('/group_nest/list', {
      ...params,
    });
  };

  // 获取群组基础信息列表
  export const getGroupNestBaseInfoList = (params: { pageNo: number; pageSize: number; keyword?: string }) => {
    return get<{ objects: Models.Group.GroupNestBaseInfo[]; totalCount: number }>('/group_nest/base_info/list', {
      ...params,
    });
  };

  // 新增群组
  export const createGroupNest = (params: PostData.GroupNest.AddGroupNestParams) => {
    return post<{ id: number }>('/group_nest/add', {}, { ...params });
  };

  // 批量新增群组
  export const batchAddGroupNest = (
    params: PostData.GroupNest.AddGroupNestParams & {
      addType: 'import' | 'ordinary';
      addCount: number;
      fileUrl?: string;
      fileId?: Id;
    },
  ) => {
    return post<{ id: number }>('/group_nest/batch/add', {}, { ...params });
  };

  // 更新群组
  export const updateGroupNest = (params: PostData.GroupNest.UpdateGroupNestParams) => {
    return post<{ id: number }>('/group_nest/update', {}, { ...params });
  };

  // 获取群组详情
  export const getGroupNestDetail = (params: { id: Id }) => {
    return get<{ data: Models.Group.GroupNest }>('/group_nest/one', { ...params });
  };

  // 批量添加群组检查
  export const batchAddGroupNestCheck = (params: {
    addType: 'import' | 'ordinary';
    addCount?: number;
    fileUrl?: string;
    fileId?: Id;
  }) => {
    return post<{ maxCount: number; totalCount: number }>('/group_nest/batch/add/check', {}, { ...params });
  };

  // 检查批量添加群组是否完成
  export const checkBatchAddGroupNestStatus = () => {
    return get<{ data: { status: 'not_running' | 'success' | 'running' | 'error'; errmsg: string } }>(
      '/group_nest/batch/add_status',
    );
  };

  /***
   * 获取群组下的群聊列表
   */
  export const getGroupChatList = (params: { groupNestId: Id; pageNo: number; pageSize: number }) => {
    return get<{ objects: Models.Group.GroupChatDetail[]; totalCount: number }>('/group_chat/list', { ...params });
  };

  /***
   * 获取群组下的群成员列表
   */
  export const getGroupMemberList = (params: { groupChatId: Id; pageNo: number; pageSize: number }) => {
    return get<{ objects: Models.Group.GroupChatMemberInfo[]; totalCount: number }>('/group_chat/member/list', {
      ...params,
    });
  };

  /**
   * 添加群组公告
   */
  export const addGroupNestNotice = (params: {
    name: string;
    groupNestIds: Id[];
    notice: string;
    sendType: 'timed' | 'instant';
    sendTime?: number;
  }) => {
    return post<{ id: number }>('/group_nest/notice/add', {}, { ...params });
  };

  /**
   * 取消群组公告
   */
  export const cancelGroupNestNotice = (params: { id: Id }) => {
    return post<void>('/group_nest/notice/cancel', {}, { ...params });
  };

  /***
   * 群公告列表
   */
  export const getGroupNestNoticeList = (params: { pageNo: number; pageSize: number }) => {
    return get<{ objects: Models.Group.GroupNotice[]; totalCount: number }>('/group_nest/notice/list', {
      ...params,
    });
  };
}
