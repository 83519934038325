import { PAGE_LINKS } from '@/helpers/commonConsts'
import { commonUtils } from '@/helpers/commonUtils'
import type { Location } from '@remix-run/router'
import { makeObservable, observable } from 'mobx'
import { NavigateFunction, SetURLSearchParams } from 'react-router-dom'
import { RouterServiceTypes as Types } from './serviceTypes'
import { message } from 'antd'

export class RouterService {
  public navigate!: NavigateFunction
  public location!: Location<any>
  public setURLSearchParams!: SetURLSearchParams

  @observable params: {
    [key: string]: string
  } = {}

  constructor() {
    makeObservable(this)
  }

  public back() {
    this.navigate(-1)
  }

  public navigateTo<P extends Types.PageType>(
    pageName: P,
    payload?: Types.PageParams[P]
  ) {
    const url = commonUtils.genUrl(PAGE_LINKS[pageName], payload || {})
    if (!url) {
      message.error('页面不存在')
    }
    return this.navigate(url)
  }

  // 获取当前页面Key
  public getCurrentPageKey() {
    const currentPath = window.location.pathname;
    // 根据值找到对应的key
    const currentPathKey = Object.keys(PAGE_LINKS).find(
      (key) => PAGE_LINKS[key as Types.PageType] === currentPath,
    ) as Types.PageType;
    return currentPathKey;
  }
}

export const routerService = new RouterService()
