import { uiController } from '@/controllers/uiController';
import { GroupApi } from '@/helpers/api/groupApi';
import { CloseOutlined } from '@ant-design/icons';
import { useInfiniteScroll } from 'ahooks';
import { Button, Divider, message, Spin } from 'antd';
import Checkbox, { CheckboxChangeEvent } from 'antd/es/checkbox';
import { observer } from 'mobx-react';
import PinyinMatch from 'pinyin-match';
import { FC, useRef, useState } from 'react';
import AmeInput from '../AmeInput/AmeInput';
import Dialog from '../Dialog/Dialog';
import IconFont from '../IconFont/IconFont';
import styles from './SelectGroupNestDialog.module.less';

export interface SelectGroupNestDialogProps {
  open: boolean;
  data: {
    title: string;
    selectedGroups?: Models.Group.GroupNestBaseInfo[];
    onOk?: (groups: Models.Group.GroupNestBaseInfo[]) => void;
  };
}

const SelectGroupNestDialog: FC<SelectGroupNestDialogProps> = observer((props: SelectGroupNestDialogProps) => {
  const {
    data: { title, onOk, selectedGroups },
    open,
  } = props;
  const [visible, setVisible] = useState(open);
  const [selectedGroupList, setSelectedGroupList] = useState<Models.Group.GroupNestBaseInfo[]>(selectedGroups || []);
  const [total, setTotal] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>('');

  const ref = useRef<HTMLDivElement>(null);

  const handleSelectAll = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      setSelectedGroupList(data?.list || []);
    } else {
      setSelectedGroupList([]);
    }
  };

  const fetchGroupList = async ({
    current,
    pageSize,
  }: {
    current: number;
    pageSize: number;
  }): Promise<{ list: Models.Group.GroupNestBaseInfo[]; total: number; current: number }> => {
    const res = await GroupApi.getGroupNestBaseInfoList({ pageNo: current, pageSize, keyword: searchValue });
    const list: Models.Group.GroupNestBaseInfo[] = res.objects.map((groupNest) => ({
      ...groupNest,
    }));
    setTotal(res.totalCount);
    return { list, total: res.totalCount, current: current + 1 };
  };

  const { data, loading, loadingMore, noMore } = useInfiniteScroll(
    (d) =>
      fetchGroupList({
        current: d?.current || 1,
        pageSize: 200,
      }),
    {
      target: ref,
      isNoMore: (d) => d?.list.length === total,
    },
  );

  const handleOk = () => {
    if (selectedGroupList.length === 0) {
      message.error('请选择群组');
      return;
    }
    onOk?.(
      selectedGroupList.map((group) => ({
        ...group,
        extId: group.id,
        extName: group.name,
      })),
    );
    handleCancel();
  };

  const handleCancel = () => {
    setVisible(false);
    setTimeout(() => {
      uiController.selectGroupNestDialogProps.open = false;
    }, 200);
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };

  const handleSelect = (group: Models.Group.GroupNestBaseInfo) => {
    if (selectedGroupList.some((item) => item.id === group.id)) {
      setSelectedGroupList(selectedGroupList.filter((item) => item.id !== group.id));
    } else {
      setSelectedGroupList([...selectedGroupList, group]);
    }
  };

  return (
    <Dialog
      title={title}
      maskClosable={false}
      className={styles.selectGroupNestDialog}
      open={visible}
      onCancel={handleCancel}
      okText="确定"
      cancelText="取消"
      onOk={handleOk}
    >
      <section className={styles.container}>
        <section className={styles.leftContainer}>
          <AmeInput
            inputType="search"
            allowClear={true}
            style={{
              width: '280px',
              marginBottom: 8,
            }}
            placeholder="请输入群组名称"
            onChange={(e) => handleSearch(e.target.value)}
          ></AmeInput>
          <div className={styles.groupListContainer}>
            <p style={{ marginBottom: 8, userSelect: 'none' }}>
              <Checkbox
                onChange={handleSelectAll}
                checked={selectedGroupList.length === data?.list?.length}
                indeterminate={selectedGroupList.length > 0 && selectedGroupList.length < total}
              >
                全选({total})
              </Checkbox>
            </p>
            <div ref={ref} className={styles.groupList}>
              {loading ? (
                <div className={styles.loading}>
                  <Spin />
                </div>
              ) : (
                <div>
                  {data?.list
                    ?.filter((item: Models.Group.GroupNestBaseInfo) => {
                      if (searchValue) {
                        return PinyinMatch.match(item.name, searchValue);
                      }
                      return true;
                    })
                    .map((item: Models.Group.GroupNestBaseInfo) => (
                      <div className={styles.groupItem} key={item.id}>
                        <Checkbox
                          onChange={() => handleSelect(item)}
                          checked={selectedGroupList.some((group) => group.id === item.id)}
                        >
                          <div className={styles.groupItemContent}>
                            <IconFont type="icon-qunzu" style={{ color: '#889FE7', fontSize: 22 }} />
                            <div className={styles.groupItemContentName}>
                              <span className={styles.groupItemContentNameText}>{item.name}</span>
                              <span className={styles.groupItemContentNameCount}>共{item.groupChatCount}个群</span>
                            </div>
                          </div>
                        </Checkbox>
                      </div>
                    ))}
                </div>
              )}
              {loadingMore && <p>加载中...</p>}
              {noMore && (
                <p style={{ textAlign: 'center', color: '#8c8c8c', fontSize: 12, marginTop: 12 }}>没有更多了</p>
              )}
            </div>
          </div>
        </section>
        <Divider type="vertical" className={styles.divider} />
        <section className={styles.rightContainer}>
          <div className={styles.selectedInfo}>
            已选群组：{selectedGroupList.length}个
            <Button type="link" onClick={() => setSelectedGroupList([])}>
              全部清空
            </Button>
          </div>
          <div className={styles.selectedGroupList}>
            {selectedGroupList.map((item) => (
              <div className={styles.selectedGroupItem} key={item.id}>
                <div className={styles.groupItemContent}>
                  <IconFont type="icon-qunzu" style={{ color: '#889FE7', fontSize: 22 }} />
                  <div className={styles.groupItemContentName}>
                    <span className={styles.groupItemContentNameText}>{item.name}</span>
                    <span className={styles.groupItemContentNameCount}>共{item.groupChatCount}个群</span>
                  </div>
                </div>

                <CloseOutlined className={styles.closeIcon} onClick={() => handleSelect(item)} />
              </div>
            ))}
          </div>
        </section>
      </section>
    </Dialog>
  );
});

export default SelectGroupNestDialog;
