class AutoSendMsgHelper {

  public transformAttachment(item: Models.AutoSend.AutoSendTaskDetail['subMissions'][number]) {
    let msgType: Models.AutoSend.MessageAttachment['msgType'] = 'text';
    const attachment: Models.AutoSend.MessageAttachment = {
      msgType,
    };
    switch (item.msgData.msgType) {
      case 'TEXT':
        msgType = 'text';
        attachment.textMsgContent = {
          text: item.msgData.textMsgContent?.content || '',
          atAll: !!item.msgData.atAll,
        };
        break;

      case 'IMAGE':
        msgType = 'image';
        attachment.imageMsgContent = {
          imageUrl: item.msgData.imageMsgContent?.imageUrl || '',
        };
        break;

      case 'LINK':
        msgType = 'image-text';
        attachment.imageTextMsgContent = {
          imageUrl: item.msgData.linkMsgContent?.imageUrl || '',
          title: item.msgData.linkMsgContent?.title || '',
          desc: item.msgData.linkMsgContent?.desc || '',
          url: item.msgData.linkMsgContent?.url || '',
        };
        break;

      case 'COUPON':
        msgType = 'coupon';
        attachment.couponMsgContent = {
          coupon: {
            activityId: item.msgData.couponMsgContent?.activityId!,
            imageUrl: item.msgData.couponMsgContent?.imageUrl,
            longIntroduceText: item.msgData.couponMsgContent?.longIntroduceText,
            title: item.msgData.couponMsgContent?.title,
          },
          couponActivity: item.couponInfo,
          moreSetting: !!item.msgData.couponMsgContent?.title,
        };
        break;

      case 'APP':
        msgType = 'tiny';
        attachment.tinyAppMsgContent = {
          imageUrl: item.msgData.tinyAppMsgContent?.imageUrl || '',
          title: item.msgData.tinyAppMsgContent?.title || '',
          desc: item.msgData.tinyAppMsgContent?.desc || '',
          tinyAppId: item.msgData.tinyAppMsgContent?.tinyAppId || '',
          url: item.msgData.tinyAppMsgContent?.url || '',
        };
        break;

      default:
        break;
    }
    attachment.msgType = msgType
    return attachment;
  }

}

export const autoSendMsgHelper = new AutoSendMsgHelper();