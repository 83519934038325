import DashboardLayout from '@/components/DashboardLayout/DashboardLayout';
import StaffTag from '@/components/StaffTag/StaffTag';
import { uiController } from '@/controllers/uiController';
import { AutoSendApi } from '@/helpers/api/autoSendApi';
import { DEFAULT_PAGE_SIZE } from '@/helpers/commonConsts';
import { dayjsHelper } from '@/helpers/dayjsHelper';
import { routerService } from '@/services/routerService';
import { useAntdTable } from 'ahooks';
import { Button, Divider, message, Row, Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { observer } from 'mobx-react';
import { useState } from 'react';
import styles from './AutoSendManagePage.module.less';

type AutoSendViewData = Models.AutoSend.AutoSendTask;

export const renderStatus = (status: Models.AutoSend.AutoSendTask['status']) => {
  const statusMap = {
    waiting: '等待中',
    sended: '已发送',
    failed: '发送失败',
    partial_failed: '部分失败',
    recalled: '已撤回',
    canceled: '已取消',
  };
  const text = statusMap[status as keyof typeof statusMap];
  let color = 'default';
  switch (status) {
    case 'waiting':
      color = 'processing';
      break;
    case 'sended':
      color = 'success';
      break;
    case 'failed':
      color = 'error';
      break;
    case 'partial_failed':
      color = 'warning';
      break;
    case 'recalled':
      color = 'warning';
      break;
    case 'canceled':
      color = 'default';
      break;
    default:
      color = 'default';
      break;
  }
  return <Tag color={color}>{text}</Tag>;
};

const AutoSendManagePage = observer(() => {
  const [total, setTotal] = useState<number>(0);

  const columns: ColumnsType<AutoSendViewData> = [
    {
      title: '任务名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '消息条数',
      dataIndex: 'msgCount',
      key: 'msgCount',
      width: 100,
    },
    {
      title: '群发类型',
      dataIndex: 'sendType',
      key: 'sendType',
      render: (sendType: Models.AutoSend.AutoSendTask['sendType']) => {
        const sendTypeMap = {
          timed: '定时群发',
          instant: '立即群发',
        } as const;
        return sendTypeMap[sendType];
      },
    },
    {
      title: '发送状态',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        return renderStatus(status);
      },
    },
    {
      title: '创建人',
      dataIndex: 'creatorInfo',
      key: 'creatorInfo',
      render: (creatorInfo) => <StaffTag name={creatorInfo.name} />,
    },
    {
      title: '发布时间',
      dataIndex: 'sendTime',
      key: 'sendTime',
      render: (time) => dayjsHelper.formatTime(time * 1000, 'YYYY/MM/DD HH:mm:ss'),
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (time) => dayjsHelper.formatTime(time * 1000, 'YYYY/MM/DD HH:mm:ss'),
    },
    {
      title: '操作',
      key: 'operation',
      align: 'right',
      render: (_, record) => {
        return (
          <>
            <Button
              type="link"
              style={{ padding: 0 }}
              onClick={() => {
                uiController.openAutoSendDetailDrawer({
                  open: true,
                  data: { task: record },
                });
              }}
            >
              详情
            </Button>
            <Divider type="vertical" />
            <Button type="link" style={{ padding: 0 }} onClick={() => handleCopy(record.id)}>
              复制
            </Button>
            {/* 未发送状态 */}
            {record.status === 'waiting' && (
              <>
                <Divider type="vertical" />
                <Button type="link" style={{ padding: 0 }} onClick={() => handleEdit(record.id)}>
                  修改
                </Button>
                <Divider type="vertical" />
                <Button type="link" style={{ padding: 0 }} onClick={() => handleCancel(record.id)}>
                  取消
                </Button>
              </>
            )}
            {/* 发送成功状态,部分失败状态 */}
            {['sended', 'partial_failed'].includes(record.status) && (
              <>
                <Divider type="vertical" />
                <Button type="link" style={{ padding: 0 }} onClick={() => handleRecall(record.id)}>
                  撤回
                </Button>
              </>
            )}
          </>
        );
      },
    },
  ];

  const handleRecall = (id: Id) => {
    AutoSendApi.recallGroupMsg({ missionId: id })
      .then(() => {
        message.success('撤回成功');
        tableRun({
          current: 1,
          pageSize: DEFAULT_PAGE_SIZE,
        });
      })
      .catch((error) => {
        message.error(error.statusMessage);
      });
  };

  const handleEdit = (id: Id) => {
    routerService.navigateTo('autoSendCreatePage', {
      id: id.toString(),
    });
  };

  const handleCopy = (id: Id) => {
    routerService.navigateTo('autoSendCreatePage', {
      id: id.toString(),
      isCopy: 'true',
    });
  };

  const handleCancel = (id: Id) => {
    AutoSendApi.cancelAutoSendTask({ missionId: id })
      .then(() => {
        message.success('取消成功');
        tableRun({
          current: 1,
          pageSize: DEFAULT_PAGE_SIZE,
        });
      })
      .catch((error) => {
        message.error(error.statusMessage);
      });
  };

  const fetchAutoSendList = async ({
    current,
    pageSize,
  }: {
    current: number;
    pageSize: number;
  }): Promise<{ list: AutoSendViewData[]; total: number }> => {
    const res = await AutoSendApi.getAutoSendTaskList({ pageNo: current, pageSize });

    const list: AutoSendViewData[] = res.objects.map((task) => ({
      ...task,
    }));

    setTotal(res.totalCount);
    return { list, total: res.totalCount };
  };

  const { tableProps, run: tableRun } = useAntdTable(fetchAutoSendList, { defaultPageSize: DEFAULT_PAGE_SIZE });

  return (
    <DashboardLayout>
      <section className={styles.autoSendManagePage}>
        <Row
          className={styles.pageHeader}
          style={{ justifyContent: 'space-between', alignItems: 'center', marginBottom: 16 }}
        >
          <span className={styles.title}>共{total}个群发</span>
          <Button type="primary" size="large" onClick={() => routerService.navigateTo('autoSendCreatePage')}>
            创建群发
          </Button>
        </Row>
        <Table
          columns={columns}
          scroll={{ x: 'max-content', y: 'calc(100vh - 260px)' }}
          {...tableProps}
          pagination={
            tableProps.pagination && tableProps.pagination.total > tableProps.pagination.pageSize
              ? tableProps.pagination
              : false
          }
          rowKey={(record) => record.id}
        />
      </section>
    </DashboardLayout>
  );
});

export default AutoSendManagePage;
