import { commonUtils } from '@/helpers/commonUtils';
import { uploadService } from '@/helpers/uploadHelper';
import { useUserService } from '@/services/userService';
import { PlusOutlined } from '@ant-design/icons';
import { message, Upload, UploadFile } from 'antd';
import { observer } from 'mobx-react';
import { FC, useEffect, useState } from 'react';
import styles from './UploadFileBox.module.less';

interface UploadFileBoxProps {
  type: 'image' | 'file';
  /**
   * 最大上传数量
   **/
  maxCount: number;
  /**
   * 最大文件大小，M为单位
   **/
  maxSize: number;
  // 上传模块
  module: PostData.FileUploadModule;
  // selectedImage
  selectedImage?: string;
  btnText?: string;
  style?: React.CSSProperties;
  className?: string;

  tips?: string;
  onOk: (fileList: Models.Upload.File[]) => void;
}

interface FileItem extends UploadFile {
  fileId?: Id;
}

const UploadFileBox: FC<UploadFileBoxProps> = observer((props: UploadFileBoxProps) => {
  const { type, maxCount, tips, maxSize, module, selectedImage, onOk } = props;
  const [accept, setAccept] = useState<string>('');
  const [btnText, setBtnText] = useState<string>('');
  const [fileList, setFileList] = useState<FileItem[]>([]);
  const { userInfo } = useUserService();

  useEffect(() => {
    if (type === 'image') {
      setAccept('image/jpeg,image/png');
      if (props.btnText) {
        setBtnText(props.btnText);
      } else {
        setBtnText('添加图片');
      }
    }
  }, [type, props.btnText]);

  useEffect(() => {
    if (selectedImage) {
      console.log(selectedImage, 'selectedImage');
      setFileList([
        {
          uid: commonUtils.genId(),
          name: 'image.png',
          status: 'done',
          url: selectedImage,
        },
      ]);
    }
  }, [selectedImage]);

  const onUpload = (info: any) => {
    const { file } = info;
    if (file.status === 'uploading') {
      uploadService
        .uploadFile({
          file: file.originFileObj,
          module,
          userId: userInfo?.id || '',
        })
        .then(({ cdnUrl, fileId }) => {
          setFileList([
            {
              uid: file.uid,
              name: file.name,
              status: 'done',
              url: cdnUrl,
              fileId,
            },
          ]);
          onOk([
            {
              fileUrl: cdnUrl,
              fileId,
              fileName: file.name,
            },
          ]);
        });
    }
  };

  const onRemove = () => {
    setFileList([]);
    onOk([]);
  };

  const beforeUpload = (file: File) => {
    if (file.size / 1024 / 1024 > maxSize) {
      message.warning(`${file.name} 文件大小超过 ${maxSize}M`);
      return Upload.LIST_IGNORE;
    }
    return true;
  };

  return (
    <div className={styles.uploadFileBox}>
      <Upload
        name="logo"
        className={`${styles.uploadBox} ${props.className}`}
        style={props.style}
        listType="picture-card"
        fileList={fileList}
        customRequest={onUpload}
        beforeUpload={beforeUpload}
        maxCount={maxCount}
        accept={accept}
        onRemove={onRemove}
        onChange={onUpload}
      >
        {fileList.length < maxCount && (
          <div className={styles.upload}>
            <PlusOutlined />
            {btnText}
          </div>
        )}
      </Upload>
      {tips && <div className={styles.imageTips}>{tips}</div>}
    </div>
  );
});

export default UploadFileBox;
