import { User } from '@/helpers/api/user';
import { commonUtils } from '@/helpers/commonUtils';
import { routerService } from '@/services/routerService';
import { RouterServiceTypes } from '@/services/routerService/serviceTypes';
import { useUserService } from '@/services/userService';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Layout as AntdLayout, Avatar, Dropdown, Menu, MenuProps, Spin, theme } from 'antd';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import GuideComponent from '../GuideComponent/GuideComponent';
import IconFont from '../IconFont/IconFont';
import styles from './DashboardLayout.module.less';

const { Content, Sider } = AntdLayout;

interface DashboardLayoutProps {
  title?: string;
  children: React.ReactNode;
  hasBack?: boolean;
  loading?: boolean;
  //是否检查功能引导
  featureGuideType?: Models.featureType;
}

type MenuItem = Required<MenuProps>['items'][number];

export interface FeatureClassify {
  icon: React.ReactNode;
  name: string;
  /** 一级菜单跳转 */
  pathKey?: RouterServiceTypes.PageType;
  /** 二级菜单 */
  children?: Feature[];
  subPagePathKeys?: RouterServiceTypes.PageType[];
}

export interface Feature {
  icon?: React.ReactNode;
  /** 功能名 */
  name: string;
  /** 功能名对应路由 */
  pathKey: RouterServiceTypes.PageType;
  /** 子页面列表 */
  subPagePathKeys: RouterServiceTypes.PageType[];
}

const MENU_MAP: FeatureClassify[] = [
  {
    icon: <IconFont type="icon-yinliuhuoke" />,
    name: '引流获客',
    children: [
      // {
      //   name: '门店活码',
      //   pathKey: 'areaScanCodeManagePage',
      //   subPagePathKeys: ['areaScanCodeCreatePage'],
      // },
      {
        name: '入群有礼',
        pathKey: 'joinGroupGiftManagePage',
        subPagePathKeys: ['joinGroupGiftCreatePage'],
      },
    ],
  },
  {
    icon: <IconFont type="icon-shequyunying" />,
    name: '社群运营',
    children: [
      {
        name: '自动建群',
        pathKey: 'inviteGroupManage',
        subPagePathKeys: ['inviteGroupCreate'],
      },
      // 批量群发
      {
        name: '自动群发',
        pathKey: 'autoSendManagePage',
        subPagePathKeys: ['autoSendCreatePage'],
      },
      // 发布群公告
      {
        name: '发布群公告',
        pathKey: 'groupNoticeManagePage',
        subPagePathKeys: ['groupNoticeCreatePage'],
      },
      // 群列表
      // {
      //   name: '群列表',
      //   pathKey: 'groupListManagePage',
      //   subPagePathKeys: ['groupListCreatePage'],
      // },
    ],
  },
  // 优惠券管理
  {
    icon: <IconFont type="icon-youhuiquanguanli" />,
    name: '优惠券管理',
    pathKey: 'couponManagePage',
    subPagePathKeys: ['couponCreatePage'],
  },
  // 账号管理
  {
    icon: <IconFont type="icon-zhanghaoguanli" />,
    name: '账号管理',
    pathKey: 'staffManagePage',
  },
];

const DashboardLayout: FC<DashboardLayoutProps> = observer((props: DashboardLayoutProps) => {
  const { children, title: propsTitle, hasBack, loading } = props;
  const [title, setTitle] = useState<string | undefined>();
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [hasBackBtn, setHasBackBtn] = useState<boolean>(false);
  useEffect(() => {
    setTitle(propsTitle);
  }, [propsTitle]);

  useEffect(() => {
    setPageLoading(loading || false);
  }, [loading]);

  useEffect(() => {
    setHasBackBtn(hasBack || false);
  }, [hasBack]);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const { userInfo } = useUserService();

  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const [menuReady, setMenuReady] = useState(false);

  useEffect(() => {
    const initializeMenu = () => {
      const currentPathKey = routerService.getCurrentPageKey();
      if (currentPathKey) {
        let newSelectedKeys: string[] = [];
        let newOpenKeys: string[] = [];

        MENU_MAP.forEach((item) => {
          if (item.children) {
            const child = item.children.find((child) => child.pathKey === currentPathKey);
            if (child) {
              newSelectedKeys = [child.name];
              newOpenKeys = [item.name];
            } else {
              const subPage = item.children.find((subChild) => subChild.subPagePathKeys.includes(currentPathKey));
              if (subPage) {
                newSelectedKeys = [subPage.name];
                newOpenKeys = [item.name];
              }
            }
          } else if (item.pathKey === currentPathKey) {
            newSelectedKeys = [item.name];
            newOpenKeys = [item.name];
          }
        });

        setSelectedKeys(newSelectedKeys);
        setOpenKeys(newOpenKeys);
      }
      setMenuReady(true);
    };

    initializeMenu();
  }, []);

  //根据 MENU_MAP 的格式生成菜单
  const generateMenu = (menu: FeatureClassify[]): MenuItem[] => {
    return menu.map((item) => {
      if (item.children) {
        const menu: MenuItem = {
          key: item.name,
          label: item.name,
          icon: item.icon,
          children: item.children.map((child) => {
            return {
              key: child.name,
              label: child.name,
              icon: child.icon,
            };
          }),
        };
        return menu;
      }
      return {
        key: item.name,
        label: item.name,
        icon: item.icon,
      };
    });
  };

  const handleMenuClick = (res: {
    key: string;
    keyPath: string[];
    /** @deprecated This will not support in future. You should avoid to use this */
    item: React.ReactInstance;
    domEvent: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>;
  }) => {
    // 找到当前点击的菜单项
    MENU_MAP.forEach((item) => {
      if (item.children) {
        const child = item.children.find((child) => child.name === res.key);
        if (child) {
          setSelectedKeys([child.name]);
          routerService.navigateTo(child.pathKey);
        }
      } else {
        if (item.name === res.key) {
          if (item.pathKey) {
            setSelectedKeys([item.name]);
            routerService.navigateTo(item.pathKey);
          }
        }
      }
    });
  };

  const handleLogout = () => {
    User.logout();
    routerService.navigateTo('login');
  };

  const sideBarItems = generateMenu(MENU_MAP);

  return (
    <AntdLayout className={classNames(styles['dashboard-layout'])}>
      <Content style={{ height: '100%' }}>
        <AntdLayout
          style={{
            background: colorBgContainer,
            height: '100vh',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Sider className={classNames(styles.sidebar)} style={{ background: colorBgContainer }} width={200}>
            <div className={styles.logo}>
              <img style={{ width: 94 }} src={commonUtils.getStaticFile('logo.png')} alt="logo" />
            </div>
            {menuReady && (
              <Menu
                mode="inline"
                openKeys={openKeys}
                onOpenChange={(openKeys) => setOpenKeys(openKeys)}
                className={styles.menu}
                onClick={handleMenuClick}
                selectedKeys={selectedKeys}
                items={sideBarItems}
              />
            )}
            <div className={styles.footer}>
              {/* 用户名 */}
              <Dropdown
                placement="topRight"
                menu={{
                  items: [{ key: 'logout', label: <div onClick={handleLogout}>登出</div> }],
                }}
              >
                <div className={styles.user}>
                  <Avatar
                    className={styles.avatar}
                    size={32}
                    src={commonUtils.getStaticFile('common/common-avatar.png')}
                  />
                  <span>{userInfo?.name}</span>
                </div>
              </Dropdown>
            </div>
          </Sider>
          <Content className={styles.layoutContent}>
            {title && (
              <p className={styles.title}>
                <span
                  className={styles.name}
                  onClick={() => {
                    routerService.back();
                  }}
                >
                  {hasBackBtn && <ArrowLeftOutlined />}
                  {title}
                </span>
              </p>
            )}
            <main className={styles.main}>
              {pageLoading && (
                <div className={styles.spinContainer}>
                  <Spin />
                </div>
              )}
              <GuideComponent>{children}</GuideComponent>
            </main>
          </Content>
        </AntdLayout>
      </Content>
    </AntdLayout>
  );
});

export default DashboardLayout;
