import { uiController } from '@/controllers/uiController';
import { CouponApi } from '@/helpers/api/couponApi';
import { DEFAULT_PAGE_SIZE } from '@/helpers/commonConsts';
import { dayjsHelper } from '@/helpers/dayjsHelper';
import { LoadingOutlined, RedoOutlined } from '@ant-design/icons';
import { useAntdTable, useRequest } from 'ahooks';
import { Button, Checkbox, Form, Radio, Select, Table, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { observer } from 'mobx-react';
import { FC, useCallback, useEffect, useState } from 'react';
import AmeInput from '../AmeInput/AmeInput';
import Dialog from '../Dialog/Dialog';
import styles from './SelectCouponDialog.module.less';

export interface SimpleCoupon {
  id: Id;
  name: string;
  extId: Id;
  voucherDescription: string;
  voucherName: string;
  publishStartTime: number;
  publishEndTime: number;
}

export interface SelectCouponDialogProps {
  open: boolean;
  data: {
    title: string;
    type?: 'single' | 'multiple';
    maxCount?: number;
    selectedCoupon?: SimpleCoupon[];
    onOk?: (coupons: SimpleCoupon[]) => void;
  };
}

const SelectCouponDialog: FC<SelectCouponDialogProps> = observer((props: SelectCouponDialogProps) => {
  const {
    data: { title, onOk, type, selectedCoupon, maxCount },
    open,
  } = props;
  const [visible, setVisible] = useState(open);
  const [selectedCouponList, setSelectedCouponList] = useState<SimpleCoupon[]>(props.data.selectedCoupon || []);
  const [couponType, setCouponType] = useState<'merchant' | 'payment'>('merchant');
  const [total, setTotal] = useState<number>(0);
  const [form] = Form.useForm();
  const [syncStatus, setSyncStatus] = useState<'running' | 'success' | 'no_running'>('no_running');

  useEffect(() => {
    setSelectedCouponList(selectedCoupon || []);
  }, [selectedCoupon]);

  const columns: ColumnsType<Models.Coupon.CouponActivity> = [
    {
      title: () => {
        return '选择优惠券';
        // if (type === 'single') {
        //   return '选择优惠券';

        // }
        // return (
        //   <>
        //     <Checkbox
        //       checked={
        //         coupons.length > 0 &&
        //         coupons.every((coupon) => selectedCouponList.map((item) => item.id).includes(coupon.id))
        //       }
        //       indeterminate={
        //         selectedCouponList.length > 0 &&
        //         selectedCouponList.length < coupons.length &&
        //         selectedCouponList.some((item) => coupons.map((coupon) => coupon.id).includes(item.id))
        //       }
        //       onChange={handleSelectAll}
        //     />
        //     <span style={{ marginLeft: '24px' }}>选择优惠券</span>
        //   </>
        // );
      },
      dataIndex: 'name',
      key: 'name',
      render: (name: string, coupon: Models.Coupon.CouponActivity) => {
        if (type === 'single') {
          return (
            <Radio
              value={coupon.id}
              checked={selectedCouponList.map((item) => item.id).includes(coupon.id)}
              onChange={() => handleRadioChange(coupon)}
            >
              <span style={{ marginLeft: 8 }}>{name}</span>
            </Radio>
          );
        }

        return (
          <Checkbox
            checked={selectedCouponList.map((item) => item.id).includes(coupon.id)}
            onChange={() => handleCheckboxChange(coupon)}
            disabled={maxCount ? selectedCouponList.length >= maxCount : false}
          >
            <span style={{ marginLeft: 24 }}>{name}</span>
          </Checkbox>
        );
      },
    },
    {
      title: '类型',
      dataIndex: 'voucherType',
      key: 'voucherType',
      render: (voucherType: Models.Coupon.VoucherTypeEnum) => {
        switch (voucherType) {
          case 'FIX_VOUCHER':
            return '折扣券';
          case 'DISCOUNT_VOUCHER':
            return '折扣券';
          case 'SPECIAL_VOUCHER':
            return '特价券';
          case 'EXCHANGE_VOUCHER':
            return '兑换券';
          default:
            return voucherType;
        }
      },
    },
    {
      title: '优惠规则',
      dataIndex: 'rule',
      key: 'rule',
    },
    {
      title: '可领时间',
      dataIndex: 'availableTime',
      key: 'availableTime',
    },
  ];

  // const handleSelectAll = (e: CheckboxChangeEvent) => {
  //   if (e.target.checked) {
  //     setSelectedCouponList(coupons);
  //   } else {
  //     setSelectedCouponList([]);
  //   }
  // };

  const fetchCouponList = useCallback(
    async ({
      current,
      pageSize,
      filters,
    }: {
      current: number;
      pageSize: number;
      filters?: {
        voucherType?: Models.Coupon.VoucherTypeEnum;
        keyword?: string;
      };
    }): Promise<{ list: Models.Coupon.CouponActivity[]; total: number }> => {
      const res = await CouponApi.getCouponActivityList({
        pageNo: current,
        pageSize,
        keyword: filters?.keyword,
        activityStatus: 'ACTIVE',
        voucherType: filters?.voucherType,
        type: couponType,
      });

      const list: Models.Coupon.CouponActivity[] = res.objects.map((coupon) => ({
        ...coupon,
        name: coupon.name || '未命名',
        sendCount: coupon.sendCount || 0,
        useCount: coupon.useCount || 0,
        availableTime: `${dayjsHelper.formatTime(coupon.publishStartTime * 1000, 'YYYY/MM/DD HH:mm:ss')} 至 ${dayjsHelper.formatTime(coupon.publishEndTime * 1000, 'YYYY/MM/DD HH:mm:ss')}`,
      }));

      setTotal(res.totalCount);
      return { list, total: res.totalCount };
    },
    [couponType],
  );

  const { tableProps, run: tableRun } = useAntdTable(fetchCouponList, {
    defaultPageSize: DEFAULT_PAGE_SIZE,
  });

  useEffect(() => {
    form.resetFields();
    tableRun({
      current: 1,
      pageSize: DEFAULT_PAGE_SIZE,
    });
  }, [couponType, form, tableRun]);

  const handleFilterChange = (filters: {
    voucherType?: Models.Coupon.VoucherTypeEnum;
    keyword?: string;
  }): Promise<void> => {
    return new Promise((resolve) => {
      tableRun({
        current: 1,
        pageSize: DEFAULT_PAGE_SIZE,
        filters,
      });
      resolve();
    });
  };

  const { run: tableDebounceRun } = useRequest(handleFilterChange, {
    debounceWait: 300,
    manual: true,
  });

  const handleSelectCouponOk = () => {
    if (selectedCouponList.length === 0) {
      message.error('请选择优惠券');
      return;
    }
    onOk?.(selectedCouponList);
    handleCancel();
  };

  const handleCancel = () => {
    setVisible(false);
    setTimeout(() => {
      uiController.selectCouponDialogProps.open = false;
    }, 200);
  };

  const handleCheckboxChange = (coupon: Models.Coupon.CouponActivity) => {
    const selectCouponList = [...selectedCouponList];
    const index = selectCouponList.map((item) => item.id).indexOf(coupon.id);

    if (index >= 0) {
      selectCouponList.splice(index, 1);
    } else {
      selectCouponList.push(coupon);
    }

    setSelectedCouponList(selectCouponList);
  };

  const handleRadioChange = (coupon: Models.Coupon.CouponActivity) => {
    setSelectedCouponList([coupon]);
  };

  const handleRefresh = () => {
    if (syncStatus === 'running') {
      return;
    }
    CouponApi.syncCoupon().then(() => {
      handleGetSyncStatus();
    });
  };

  const handleGetSyncStatus = useCallback(() => {
    // 获取同步状态
    CouponApi.getCouponSyncStatus().then(({ data }) => {
      const { status } = data;
      setSyncStatus(status);
      if (status === 'running') {
        setTimeout(() => {
          handleGetSyncStatus();
        }, 3000);
      }
      if (status === 'success') {
        tableRun({
          current: 1,
          pageSize: DEFAULT_PAGE_SIZE,
        });
      }
    });
  }, [tableRun]);

  return (
    <Dialog
      title={title}
      maskClosable={false}
      className={styles.selectCouponDialog}
      open={visible}
      width={891}
      onCancel={handleCancel}
      okText="确定"
      cancelText="取消"
      footer={
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={handleCancel}>取消</Button>
          <Button type="primary" onClick={handleSelectCouponOk}>
            确定
          </Button>
        </div>
      }
    >
      <Radio.Group
        onChange={(e) => {
          const newCouponType = e.target.value;
          setCouponType(newCouponType);
        }}
        value={couponType}
        style={{ marginBottom: 24 }}
        buttonStyle="solid"
        size="middle"
      >
        <Radio.Button value="merchant">商家券</Radio.Button>
        <Radio.Button value="payment">支付券</Radio.Button>
      </Radio.Group>
      <Form
        layout="inline"
        form={form}
        onValuesChange={(_, allValues) => {
          tableDebounceRun(allValues);
        }}
      >
        <Form.Item name="keyword" label="活动名称">
          <AmeInput placeholder="请输入活动名称进行搜索" style={{ width: 216 }} inputType="search" />
        </Form.Item>
        <Form.Item name="voucherType" label="类型">
          <Select placeholder="请选择优惠券类型" style={{ width: 200 }} allowClear>
            <Select.Option value="FIX_VOUCHER">满减券</Select.Option>
            <Select.Option value="DISCOUNT_VOUCHER">折扣券</Select.Option>
            <Select.Option value="SPECIAL_VOUCHER">特价券</Select.Option>
            <Select.Option value="EXCHANGE_VOUCHER">兑换券</Select.Option>
          </Select>
        </Form.Item>
      </Form>
      <Table
        columns={columns}
        rowKey="id"
        {...tableProps}
        style={{
          minHeight: 400,
        }}
        pagination={total > tableProps.pagination?.pageSize ? tableProps.pagination : false}
        title={() => (
          <div>
            共{total}张可用商家券{' '}
            <Button type="link" style={{ padding: 0, marginLeft: 4, marginRight: 4 }} onClick={handleRefresh}>
              {syncStatus === 'running' ? <LoadingOutlined /> : <RedoOutlined />}
              刷新
            </Button>
            <span className={styles.refreshTip}>（仅支持选择已激活状态优惠券）</span>
          </div>
        )}
      />
    </Dialog>
  );
});

export default SelectCouponDialog;
