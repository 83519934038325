import { get, post } from './common';

export namespace AutoSendApi {
  // 获取自动发送任务列表
  export const getAutoSendTaskList = (params: { pageNo: number; pageSize: number; keyword?: string }) => {
    return get<{ hasNext: boolean; objects: Models.AutoSend.AutoSendTask[]; totalCount: number }>('/group_msg/list', {
      ...params,
    });
  };

  // 新增自动发送任务
  export const createAutoSendTask = (params: PostData.AutoSend.AddAutoSendTaskParams) => {
    return post<{ id: number }>('/group_msg/add', {}, { ...params });
  };

  // 更新自动发送任务
  export const updateAutoSendTask = (params: PostData.AutoSend.UpdateAutoSendTaskParams) => {
    return post<{ id: number }>('/group_msg/update', {}, { ...params });
  };

  // 获取自动发送任务详情
  export const getAutoSendTaskDetail = (params: { id: Id }) => {
    return get<{ data: Models.AutoSend.AutoSendTaskDetail }>('/group_msg/one', { ...params });
  };

  // 取消自动发送任务
  export const cancelAutoSendTask = (params: { missionId: Id }) => {
    return post('/group_msg/cancel', {}, { ...params });
  };

  // 群发任务撤回
  export const recallGroupMsg = (params: { missionId: Id }) => {
    return post('/group_msg/recall', {}, { ...params });
  };
}
