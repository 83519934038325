import { App as AntApp, ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import { useEffect, useLayoutEffect } from 'react';
import { BrowserRouter, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import './App.less';
import { Router } from './Router';
import CommonDialogLayout from './components/CommonDialogLayout/CommonDialogLayout';
import { uiController } from './controllers/uiController';
import { commonUtils } from './helpers/commonUtils';
import { themeHelper } from './helpers/themeHelper';
import { routerService } from './services/routerService/routerService';
import { useUserService } from './services/userService';

themeHelper.init();

const RouterLayout = () => {
  // 只要使用了 useNavigate ，url 变化都会导致此组件重新渲染
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const params: any = useParams();
  routerService.navigate = useNavigate();
  const location = useLocation();
  routerService.location = location;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams();
  routerService.setURLSearchParams = setSearchParams;
  useLayoutEffect(() => {
    routerService.params = {
      ...params,
      ...commonUtils.getUrlParams(),
    };
  });
  return null;
};

export default function App() {
  const { getUserInfo } = useUserService();
  useEffect(() => {
    getUserInfo();
  }, [getUserInfo]);

  return (
    <ConfigProvider autoInsertSpaceInButton={false} theme={themeHelper.theme} locale={zhCN}>
      <BrowserRouter>
        <AntApp>
          <CommonDialogLayout />
          <RouterLayout />
          <Router />
        </AntApp>
      </BrowserRouter>
    </ConfigProvider>
  );
}

uiController.init();
