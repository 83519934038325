import { User } from '@/helpers/api/user';
import { message } from 'antd';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { routerService } from '../routerService';

// 定义Context的类型
interface UserContextType {
  userInfo: Models.User.Staff | null;
  setUserInfo: (userInfo: Models.User.Staff) => void;
}

// 创建上下文
const UserContext = createContext<UserContextType | undefined>(undefined);

// 自定义 Hook 来使用用户服务
export const useUserService = () => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('useUserService must be used within a UserProvider');
  }

  const { userInfo, setUserInfo } = context;

  // 获取用户信息的函数
  const getUserInfo = useCallback(() => {
    return new Promise<Models.User.Staff>((resolve) => {
      User.getStaffMe()
        .then((staff) => {
          setUserInfo(staff);
          resolve(staff);
        })
        .catch((err) => {
          if (err.statusCode === 10031) {
            routerService.navigateTo('login');
          } else {
            message.error(err.statusMessage);
          }
          // reject(err);
        });
    });
  }, [setUserInfo]);

  return {
    userInfo,
    getUserInfo,
  };
};

// 创建一个提供者组件
export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [userInfo, setUserInfo] = useState<Models.User.Staff | null>(null);

  // 使用 useEffect 来监视 userInfo 的变化（可选）
  useEffect(() => {
    // 这里你可以添加任何需要在 userInfo 更新时执行的逻辑
  }, [userInfo]);

  return <UserContext.Provider value={{ userInfo, setUserInfo }}>{children}</UserContext.Provider>;
};
