import dayjs from 'dayjs';
import zh from 'dayjs/locale/zh-cn';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);
dayjs.locale(zh);

class DayjsHelper {
  public fromNow(date: string | number) {
    const format = 'YYYY-MM-DD';
    const now = dayjs();
    const diff = now.diff(dayjs(date), 'day');

    if (diff > 7) {
      return dayjs(dayjs(date)).format(format);
    }

    return dayjs(dayjs(date)).fromNow();
  }

  /**
   * 格式化时间
   * @param date 时间
   * @param template 模板例子：YYYY-MM-DD HH:mm:ss
   */
  public formatTime(date: string | number, template = 'YYYY-MM-DD') {
    // Aug 16, 2018 12:00
    return dayjs(date).format(template);
  }

  public diffDay(start: string | number, end: string | number) {
    return dayjs(end).diff(dayjs(start), 'day');
  }

  // 获取禁用时间
  public getDisabledTime(current?: dayjs.Dayjs) {
    if (!current) {
      return {
        disabledHours: () => [],
        disabledMinutes: () => [],
        disabledSeconds: () => [],
      };
    }
    const now = dayjs();
    if (!current || current.isBefore(now, 'minute')) {
      return {
        disabledHours: () => Array.from({ length: 24 }, (_, i) => i),
        disabledMinutes: () => Array.from({ length: 60 }, (_, i) => i),
        disabledSeconds: () => Array.from({ length: 60 }, (_, i) => i),
      };
    }

    return {
      disabledHours: () => {
        const hours = [];
        for (let i = 0; i < now.hour(); i++) {
          hours.push(i);
        }
        return hours;
      },
      disabledMinutes: () => {
        const minutes = [];
        if (current.hour() === now.hour()) {
          for (let i = 0; i < now.minute(); i++) {
            minutes.push(i);
          }
        }
        return minutes;
      },
      disabledSeconds: () => {
        const seconds = [];
        if (current.hour() === now.hour() && current.minute() === now.minute()) {
          for (let i = 0; i < now.second(); i++) {
            seconds.push(i);
          }
        }
        return seconds;
      },
    };

  }
}

export const dayjsHelper = new DayjsHelper();
