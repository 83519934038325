import { get, post } from './common';

export namespace ossConfig {
  export function getPrivateToken(module: PostData.FileUploadModule) {
    return get<{ config: Models.Oss.Config; token: Models.Oss.Token }>('/oss/sts_token/private', { module })
  }

  export function getPublicToken(module: PostData.FileUploadModule) {
    return get<{ config: Models.Oss.Config; token: Models.Oss.Token }>('/oss/sts_token/public', { module })
  }
}

export namespace Upload {
  // 获取前端直传签名地址
  export function getUploadSignUrl(data: {
    module: PostData.FileUploadModule
    filename: string
    // 文件大小，单位：字节
    contentLength: number
  }): Promise<
    ApiTypes.ApiBaseResult & {
      data: {
        signUrl: string
        fileId: number
        fileName: string
      }
    }
  > {
    return post('/upload/sign_url', {}, { ...data })
  }

  // 获取上传文件的下载地址
  export function getFileUrl(
    fileId: number,
  ): Promise<
    ApiTypes.ApiBaseResult & {
      /** pdf 下载地址 */
      downloadUrl: string
      /** 文件名字 */
      fileName: string
      /** fileId */
      fileId: number
    }
  > {
    const params: Record<string, any> = {}
    if (fileId !== undefined) {
      params['fileId'] = fileId
    }

    return get('/download', params)
  }

  export function updateUploadStatus(
    params: { fileId: number },
  ) {
    return get('/upload/update_status', params, )
  }


}
