import { CloseOutlined } from '@ant-design/icons'; // Import the UserOutlined component
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { FC } from 'react';
import AmeToolTip from '../AmeToolTip/AmeToolTip';
import IconFont from '../IconFont/IconFont';
import styles from './StaffTag.module.less';

interface StaffTagProps {
  name: string;
  tip?: string;
  disable?: boolean;
  close?: () => void;
}

const StaffTag: FC<StaffTagProps> = observer((props: StaffTagProps) => {
  return (
    <AmeToolTip title={props.tip || props.name}>
      <div className={classNames(styles.staffTag, { [styles.disable]: props.disable })}>
        <IconFont type="icon-yuangong" style={{ color: '#889FE7' }} />
        <span className={styles.name}>{props.name}</span>
        {props.close && <CloseOutlined className={styles.closeIcon} onClick={props.close} />}
      </div>
    </AmeToolTip>
  );
});

export default StaffTag;
