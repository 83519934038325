import { uiController } from '@/controllers/uiController';
import { User } from '@/helpers/api/user';
import { DEFAULT_PAGE_SIZE } from '@/helpers/commonConsts';
import { useAntdTable } from 'ahooks';
import { Button, Checkbox, Radio, Table, message } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { ColumnsType } from 'antd/es/table';
import { observer } from 'mobx-react';
import { FC, useEffect, useState } from 'react';
import AddGroupAdminDialog from '../AddGroupAdminDialog/AddGroupAdminDialog';
import Dialog from '../Dialog/Dialog';
import styles from './SelectUserDialog.module.less';

export interface SelectUserDialogProps {
  open: boolean;
  data: {
    title: string;
    // 单选还是多选
    type?: 'single' | 'multiple';
    selectedAdmins?: Models.User.MemberItem[];
    onOk?: (users: Models.User.MemberItem[]) => void;
  };
}

const SelectUserDialog: FC<SelectUserDialogProps> = observer((props: SelectUserDialogProps) => {
  const {
    data: { title, onOk, selectedAdmins, type },
    open,
  } = props;
  const [isAddAdminModalVisible, setIsAddAdminModalVisible] = useState(false);
  const [visible, setVisible] = useState(open);
  const [selectedAdminList, setSelectedAdminList] = useState<Models.User.MemberItem[]>([]);
  const [admins, setAdmins] = useState<Models.User.UserDetail[]>([]);
  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    setSelectedAdminList(selectedAdmins || []);
  }, [selectedAdmins]);

  const columns: ColumnsType<Models.User.UserDetail> = [
    {
      title: () => {
        if (type === 'single') {
          return '选择账号';
        }
        return (
          <>
            <Checkbox
              checked={
                admins.length !== 0 &&
                selectedAdminList.length ===
                  admins.filter((item) => {
                    return item.status === 'AUTHORIZED';
                  }).length
              }
              indeterminate={
                selectedAdminList.length > 0 &&
                selectedAdminList.length <
                  admins.filter((item) => {
                    return item.status === 'AUTHORIZED';
                  }).length
              }
              onChange={handleSelectAll}
            />
            <span style={{ marginLeft: '8px' }}>账号名</span>
          </>
        );
      },
      dataIndex: 'userName',
      key: 'userName',
      render: (name: string, user: Models.User.UserDetail) => {
        if (type === 'single') {
          // 返回单选框
          return (
            <span>
              <Radio
                value={user.id}
                disabled={user.status !== 'AUTHORIZED'}
                checked={selectedAdminList.map((item) => item.id).includes(user.id)}
                onChange={() => {
                  handleRadioChange(user);
                }}
              >
                <span style={{ marginLeft: 8 }}>{name}</span>
              </Radio>
            </span>
          );
        }
        return (
          <span>
            <Checkbox
              disabled={user.status !== 'AUTHORIZED'}
              checked={selectedAdminList
                .map((item) => {
                  return item.id;
                })
                .includes(user.id)}
              onChange={() => handleCheckboxChange(user)}
            />
            <span style={{ marginLeft: 8 }}>{name}</span>
          </span>
        );
      },
    },
    {
      title: '支付宝账号',
      dataIndex: 'loginId',
      key: 'loginId',
    },
    {
      title: '账号状态',
      dataIndex: 'status',
      key: 'status',
      align: 'right' as const,
      render: (status: Models.User.UserDetail['status']) => (
        <span className={`${styles.status} ${status === 'AUTHORIZED' ? styles.green : styles.red}`}>
          {status === 'AUTHORIZED' ? '正常' : '未授权'}
        </span>
      ),
    },
  ];

  const handleSelectAll = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      setSelectedAdminList(
        admins
          .filter((item) => item.status === 'AUTHORIZED')
          .map((item) => {
            return {
              ...item,
              name: item.userName,
            };
          }),
      );
    } else {
      setSelectedAdminList([]);
    }
  };

  /** 加载群聊 */
  const fetchUserList = async ({
    current,
    pageSize,
  }: {
    current: number;
    pageSize: number;
  }): Promise<{ list: Models.User.UserDetail[]; total: number }> => {
    const res = await User.getGroupAuthorizeList({ pageNo: current, pageSize });
    const list: Models.User.UserDetail[] = res.objects.map((user) => {
      return {
        ...user,
        key: user.id,
      };
    });
    setAdmins(list);
    setTotal(res.totalCount);
    const data = {
      list,
      total: res.totalCount,
    };
    return data;
  };

  const { tableProps, run: fetchUserListRun } = useAntdTable(fetchUserList, { defaultPageSize: DEFAULT_PAGE_SIZE });

  /***
   * 点击确定按钮
   */
  const handleSelectAdminOk = () => {
    // 处理选择管理员的逻辑
    if (selectedAdminList.length === 0) {
      message.error('请选择管理员');
      return;
    }
    onOk?.(selectedAdminList);
    handleCancel();
  };

  // 完成选择管理员
  const handleAddAdminOk = () => {
    // 处理添加管理员的逻辑
    setIsAddAdminModalVisible(false);
    fetchUserListRun({ current: 1, pageSize: DEFAULT_PAGE_SIZE });
  };

  // 关闭弹窗
  const handleCancel = () => {
    setVisible(false);
    setTimeout(() => {
      uiController.selectUserDialogProps.open = false;
    }, 200);
  };

  const handleCheckboxChange = (user: Models.User.UserDetail) => {
    if (user.status !== 'AUTHORIZED') {
      return;
    }
    if (type === 'single') {
      setSelectedAdminList([
        {
          ...user,
          name: user.userName,
        },
      ]);
    } else {
      const selectUserList = [...selectedAdminList];
      const index = selectUserList.map((item) => item.id).indexOf(user.id);

      if (index >= 0) {
        selectUserList.splice(index, 1);
      } else {
        selectUserList.push({
          ...user,
          name: user.userName,
        });
      }

      setSelectedAdminList(selectUserList);
    }
  };

  const handleRadioChange = (user: Models.User.UserDetail) => {
    setSelectedAdminList([
      {
        ...user,
        name: user.userName,
      },
    ]);
  };

  return (
    <>
      <Dialog
        title={title}
        maskClosable={false}
        className={styles.selectUserDialog}
        width={772}
        open={visible}
        onCancel={handleCancel}
        okText="确定"
        cancelText="取消"
        footer={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              没有想要添加的账号？
              <Button
                type="link"
                style={{ padding: 0 }}
                onClick={() => {
                  setIsAddAdminModalVisible(true);
                }}
              >
                添加管理员账号
              </Button>
            </div>
            <div>
              <Button onClick={handleCancel}>取消</Button>
              <Button type="primary" onClick={handleSelectAdminOk}>
                确定
              </Button>
            </div>
          </div>
        }
      >
        <div style={{ marginBottom: 16 }}>全部账号（{total}）</div>
        <Table
          columns={columns}
          rowKey="id"
          onRow={(user) => ({
            onClick: () => handleCheckboxChange(user), // 点击行触发选择
            style: { cursor: 'pointer' },
          })}
          {...tableProps}
          pagination={total > tableProps.pagination?.pageSize ? tableProps.pagination : false}
          scroll={{ y: 310 }}
        />
      </Dialog>

      <AddGroupAdminDialog
        visible={isAddAdminModalVisible}
        onClose={() => setIsAddAdminModalVisible(false)}
        onSuccess={handleAddAdminOk}
      />
    </>
  );
});

export default SelectUserDialog;
