import { uiController } from '@/controllers/uiController';
import { commonUtils } from '@/helpers/commonUtils';
import { dayjsHelper } from '@/helpers/dayjsHelper';
import { routerService } from '@/services/routerService';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Drawer, Popover, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { observer } from 'mobx-react';
import { FC, useState } from 'react';
import IconFont from '../IconFont/IconFont';
import styles from './JoinGroupGiftDetailDrawer.module.less';

export type JoinGroupGiftDetailDrawerProps = {
  open: boolean;
  data: {
    giftDetail?: Models.GroupGift.GroupGiftObj;
  };
};

const JoinGroupGiftDetailDrawer: FC<JoinGroupGiftDetailDrawerProps> = observer(
  (props: JoinGroupGiftDetailDrawerProps) => {
    const [open, setOpen] = useState<boolean>(true);
    const {
      data: { giftDetail },
    } = props;

    if (!giftDetail) {
      return null;
    }

    const columns: ColumnsType<Models.GroupGift.GroupGiftObj['joinGifts'][number]> = [
      {
        title: '生效场景',
        dataIndex: 'channelName',
        key: 'channelName',
      },
      {
        title: '优惠券配置',
        dataIndex: 'activityName',
        key: 'activityName',
        render: (activityName: string) => {
          return (
            <div className={styles.tableCouponConfig}>
              <img src={commonUtils.getStaticFile('coupon/coupon-icon.png')} alt="" />
              <span>{activityName}</span>
            </div>
          );
        },
      },
      {
        title: '生效时间',
        dataIndex: 'time',
        key: 'time',
        render: (_, record: Models.GroupGift.GroupGiftObj['joinGifts'][number]) => {
          return (
            <span>
              {dayjsHelper.formatTime(record.startTime, 'YYYY-MM-DD HH:mm:ss')} -{' '}
              {dayjsHelper.formatTime(record.endTime, 'YYYY-MM-DD HH:mm:ss')}
            </span>
          );
        },
      },
      {
        title: () => {
          return (
            <>
              <span>
                状态
                <Popover
                  placement="top"
                  content={
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
                      <p>
                        <Tag color="success" style={{ marginRight: 8 }}>
                          生效中
                        </Tag>
                        该场景配置正在生效中
                      </p>
                      <p>
                        <Tag color="error" style={{ marginRight: 8 }}>
                          已失效
                        </Tag>
                        该场景配置已暂停，可重新启用
                      </p>
                      <p>
                        <Tag color="warning" style={{ marginRight: 8 }}>
                          已暂停
                        </Tag>
                        该场景配置的优惠券已领完，入群有礼福利已失效
                      </p>
                      <p>
                        <Tag color="blue" style={{ marginRight: 8 }}>
                          待生效
                        </Tag>
                        暂未到该场景配置的生效起始时间，配置待生效
                      </p>
                      <p>
                        <Tag color="default" style={{ marginRight: 8 }}>
                          已到期
                        </Tag>
                        已过该场景配置的生效结束时间，配置已过期
                      </p>
                    </div>
                  }
                >
                  <QuestionCircleOutlined style={{ marginLeft: 8, cursor: 'pointer' }} />
                </Popover>
              </span>
            </>
          );
        },
        width: 120,
        dataIndex: 'giftStatus',
        key: 'giftStatus',
        render: (giftStatus: string) => {
          let text = '';
          switch (giftStatus) {
            case 'valid':
              text = '生效中';
              return <Tag color="success">{text}</Tag>;
            case 'invalid':
              text = '已失效';
              return <Tag color="error">{text}</Tag>;
            case 'suspend':
              text = '已暂停';
              return <Tag color="warning">{text}</Tag>;
            case 'expire':
              text = '已到期';
              return <Tag color="default">{text}</Tag>;
            case 'init':
              text = '待生效';
              return <Tag color="blue">{text}</Tag>;
            default:
              break;
          }
          return <span>{text}</span>;
        },
      },
      {
        title: '操作',
        dataIndex: 'operation',
        key: 'operation',
        width: 100,
        align: 'end',
        render: (_, record: Models.GroupGift.GroupGiftObj['joinGifts'][number]) => {
          return (
            <>
              <Button
                type="link"
                style={{ padding: 0, marginRight: 8 }}
                onClick={() => {
                  console.log('暂停', record);
                }}
              >
                暂停
              </Button>
              <Button
                type="link"
                style={{ padding: 0 }}
                onClick={() => {
                  console.log('删除', record);
                }}
              >
                删除
              </Button>
            </>
          );
        },
      },
    ];

    return (
      <Drawer
        title="入群有礼详情"
        placement="right"
        closable={true}
        width={999}
        className={styles.joinGroupGiftDetailDrawer}
        extra={
          <Button
            type="primary"
            onClick={() => {
              if (giftDetail) {
                // 跳转到修改页面
                routerService.navigateTo('joinGroupGiftCreatePage', { id: giftDetail.id.toString() });
                setOpen(false);
                setTimeout(() => {
                  uiController.joinGroupGiftDetailDrawerProps.open = false;
                }, 200);
              }
            }}
          >
            修改
          </Button>
        }
        onClose={() => {
          setOpen(false);
          setTimeout(() => {
            uiController.joinGroupGiftDetailDrawerProps.open = false;
          }, 200);
        }}
        open={open}
      >
        <section className={styles.main}>
          <div className={styles.header}>
            <p className={styles.title}>
              <IconFont className={styles.icon} type="icon-qunzu" />
              {giftDetail.groupNestInfo.name}
            </p>

            <div className={styles.info}>
              <div className={styles.cardItemBox}>
                <div className={styles.cardItem}>
                  <div className={styles.cardItemTitle}>
                    {giftDetail.joinGifts.length}
                    <span className={styles.cardItemTitleUnit}>个</span>
                  </div>
                  <div className={styles.cardItemDesc}>
                    <span>入群有礼总数</span>
                  </div>
                </div>
              </div>
              <div className={styles.cardItemBox}>
                <div className={styles.cardItem}>
                  <div className={styles.cardItemTitle}>
                    {giftDetail.joinGifts.filter((item) => item.giftStatus === 'valid').length}
                    <span className={styles.cardItemTitleUnit}>个</span>
                  </div>
                  <div className={styles.cardItemDesc}>
                    <span className={`${styles.cardItemDescDot} ${styles.cardItemDescDotGreen}`}></span>
                    <span>生效中</span>
                  </div>
                </div>

                <div className={styles.cardItem}>
                  <div className={styles.cardItemTitle}>
                    {giftDetail.joinGifts.filter((item) => item.giftStatus === 'suspend').length}
                    <span className={styles.cardItemTitleUnit}>个</span>
                  </div>
                  <div className={styles.cardItemDesc}>
                    <span className={`${styles.cardItemDescDot} ${styles.cardItemDescDotOrange}`}></span>
                    <span>已暂停</span>
                  </div>
                </div>

                <div className={styles.cardItem}>
                  <div className={styles.cardItemTitle}>
                    {giftDetail.joinGifts.filter((item) => item.giftStatus === 'init').length}
                    <span className={styles.cardItemTitleUnit}>个</span>
                  </div>
                  <div className={styles.cardItemDesc}>
                    <span className={`${styles.cardItemDescDot} ${styles.cardItemDescDotBlue}`}></span>
                    <span>待生效</span>
                  </div>
                </div>

                <div className={styles.cardItem}>
                  <div className={styles.cardItemTitle}>
                    {giftDetail.joinGifts.filter((item) => item.giftStatus === 'invalid').length}
                    <span className={styles.cardItemTitleUnit}>个</span>
                  </div>
                  <div className={styles.cardItemDesc}>
                    <span className={`${styles.cardItemDescDot} ${styles.cardItemDescDotRed}`}></span>
                    <span>已失效</span>
                  </div>
                </div>

                <div className={styles.cardItem}>
                  <div className={styles.cardItemTitle}>
                    {giftDetail.joinGifts.filter((item) => item.giftStatus === 'expire').length}
                    <span className={styles.cardItemTitleUnit}>个</span>
                  </div>
                  <div className={styles.cardItemDesc}>
                    <span className={`${styles.cardItemDescDot} ${styles.cardItemDescDotGray}`}></span>
                    <span>已到期</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.line}></div>

          <div className={styles.content}>
            <div className={styles.contentTitle}>
              入群有礼配置列表
              <p className={styles.contentTitleDesc}>单场景配置展示优先级高于全部场景配置</p>
            </div>

            <Table
              columns={columns}
              dataSource={giftDetail.joinGifts}
              pagination={false}
              rowKey={commonUtils.genId()}
            ></Table>
          </div>
        </section>
      </Drawer>
    );
  },
);

export default JoinGroupGiftDetailDrawer;
