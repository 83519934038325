import { uiController } from '@/controllers/uiController';
import { GroupApi } from '@/helpers/api/groupApi';
import { DEFAULT_PAGE_SIZE } from '@/helpers/commonConsts';
import { commonUtils } from '@/helpers/commonUtils';
import { generateCustomQrcode } from '@/helpers/qrcode';
import { routerService } from '@/services/routerService';
import { CopyOutlined, DownloadOutlined } from '@ant-design/icons';
import { useAntdTable } from 'ahooks';
import { Button, Descriptions, Drawer, Radio, Table, message } from 'antd';
import { observer } from 'mobx-react';
import { FC, useEffect, useState } from 'react';
import Empty from '../Empty/Empty';
import MessageCardItem from '../MessageCardItem/MessageCardItem';
import StaffTag from '../StaffTag/StaffTag';
import styles from './GroupNestDetailDrawer.module.less';

export type GroupNestDetailDrawerProps = {
  open: boolean;
  data: {
    groupNest?: Models.Group.GroupNest;
  };
};

const GroupNestDetailDrawer: FC<GroupNestDetailDrawerProps> = observer((props: GroupNestDetailDrawerProps) => {
  const [open, setOpen] = useState<boolean>(true);
  const [currentTabKey, setCurrentTabKey] = useState<'groupNestDetail' | 'groupDetail'>('groupNestDetail');
  const {
    data: { groupNest },
  } = props;

  const onTabChange = (e: any) => {
    setCurrentTabKey(e.target.value);
  };

  return (
    <Drawer
      title="建群详情"
      placement="right"
      closable={true}
      width={800}
      extra={
        <Button
          type="primary"
          onClick={() => {
            if (groupNest) {
              routerService.navigate(commonUtils.getPageUrl('inviteGroupCreate', { id: groupNest.id.toString() }));
              setOpen(false);
              setTimeout(() => {
                uiController.groupNestDetailDrawerProps.open = false;
              }, 200);
            }
          }}
        >
          修改
        </Button>
      }
      onClose={() => {
        setOpen(false);
        setTimeout(() => {
          uiController.groupNestDetailDrawerProps.open = false;
        }, 200);
      }}
      open={open}
    >
      <Radio.Group
        onChange={onTabChange}
        value={currentTabKey}
        style={{ marginBottom: 24 }}
        buttonStyle="solid"
        size="large"
      >
        <Radio.Button value="groupNestDetail">群组详情</Radio.Button>
        <Radio.Button value="groupDetail">群详情</Radio.Button>
      </Radio.Group>
      {currentTabKey === 'groupNestDetail' && groupNest && <GroupNestDetail groupNest={groupNest} />}
      {currentTabKey === 'groupDetail' && groupNest && <GroupDetail id={groupNest.id} />}
    </Drawer>
  );
});

interface GroupNestDetailProps {
  groupNest: Models.Group.GroupNest;
}

const GroupNestDetail: FC<GroupNestDetailProps> = (props) => {
  const { groupNest } = props;

  const [qrcode, setQrcode] = useState<string>('');
  const [welcomeText, setWelcomeText] = useState<string>('');
  const [welcomeGroupMsg, setWelcomeGroupMsg] = useState<Models.Message.MessageAttachment>();

  useEffect(() => {
    console.log('groupNest useEffect', groupNest);
    generateCustomQrcode({
      content: groupNest.promotionUrl,
    }).then((code) => {
      setQrcode(code);
    });

    if (groupNest.welcomeMsg) {
      setWelcomeText(groupNest.welcomeMsg.welcomeText);
      setWelcomeGroupMsg(groupNest.welcomeMsg.welcomeGroupMsg);
    }
  }, [groupNest]);

  return (
    <div>
      <Descriptions title="基本信息" column={1} className={styles.descriptionsTitle}>
        <Descriptions.Item label="群组名称">{groupNest.name}</Descriptions.Item>
        <Descriptions.Item label="群组二维码">
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <img src={qrcode} style={{ width: '118px' }} />
            <Button
              style={{ marginLeft: '14px' }}
              onClick={() => commonUtils.downloadFile(qrcode, `${groupNest.name}.png`)}
            >
              <DownloadOutlined />
              下载
            </Button>
          </div>
        </Descriptions.Item>
        <Descriptions.Item label="群组链接">
          <div>
            {groupNest.promotionUrl}
            {/* 复制 */}
            <Button
              type="link"
              onClick={() => {
                commonUtils.copyToClipboard(groupNest.promotionUrl);
                message.success('复制成功');
              }}
            >
              <CopyOutlined />
              复制
            </Button>
          </div>
        </Descriptions.Item>
        <Descriptions.Item label="群主">
          {groupNest.groupChatMasterInfo ? (
            <StaffTag name={groupNest.groupChatMasterInfo.userName} />
          ) : (
            <StaffTag name="未知成员" disable tip="该账号未授权，请及时更换群主" />
          )}
        </Descriptions.Item>
        <Descriptions.Item label="群管理员">
          {groupNest.groupChatAdminInfoList.length > 0
            ? groupNest.groupChatAdminInfoList.map((manager) => <StaffTag key={manager.id} name={manager.userName} />)
            : '-'}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions title="群组消息设置" column={1} className={styles.descriptionsTitle}>
        <Descriptions.Item label="入群欢迎语">
          <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
            {welcomeText || welcomeGroupMsg ? (
              <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                <p>{welcomeText}</p>
                {welcomeGroupMsg && <MessageCardItem msg={welcomeGroupMsg} />}
              </div>
            ) : (
              <p>-</p>
            )}
          </div>
        </Descriptions.Item>
        <Descriptions.Item label="群介绍">{groupNest.groupChatDesc || '-'}</Descriptions.Item>
      </Descriptions>

      <Descriptions title="高级设置" column={1} className={styles.descriptionsTitle}>
        <Descriptions.Item label="自动扩群">
          {groupNest.autoCreateGroupInstance ? '已开启' : '已关闭'}
        </Descriptions.Item>
        <Descriptions.Item label="允许邀请入群">{groupNest.openInvite ? '已开启' : '已关闭'}</Descriptions.Item>
        <Descriptions.Item label="允许管理员私聊">{groupNest.forbidAdminChat ? '已关闭' : '已开启'}</Descriptions.Item>
        <Descriptions.Item label="允许成员间私聊">{groupNest.forbidMemberChat ? '已关闭' : '已开启'}</Descriptions.Item>
      </Descriptions>
    </div>
  );
};

interface GroupDetailProps {
  id: Id;
}
const GroupDetail: FC<GroupDetailProps> = (props) => {
  const { id } = props;

  const columns = [
    {
      title: '群名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '群主',
      dataIndex: 'groupChatMasterInfo',
      key: 'groupChatMasterInfo',
      render: (groupChatMasterInfo: Models.Group.GroupChatMemberInfo) => {
        if (groupChatMasterInfo) {
          return <StaffTag name={groupChatMasterInfo.userName}></StaffTag>;
        }
        return <StaffTag name="未知成员" disable tip="该账号未授权，请及时更换群主" />;
      },
    },
    {
      title: '群管理员',
      dataIndex: 'groupChatAdminInfoList',
      key: 'groupChatAdminInfoList',
      render: (groupChatAdminInfoList: Models.Group.GroupChatMemberInfo[]) => {
        return (
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
            {groupChatAdminInfoList.length > 0
              ? groupChatAdminInfoList.map((admin, index) => <StaffTag key={index} name={admin.userName}></StaffTag>)
              : '-'}
          </div>
        );
      },
    },
    {
      title: '群人数',
      dataIndex: 'memberCount',
      key: 'memberCount',
    },
    {
      title: '当日进群',
      dataIndex: 'todayJoinCount',
      key: 'todayJoinCount',
    },
    {
      title: '当日退群',
      dataIndex: 'todayQuitCount',
      key: 'todayQuitCount',
    },
    {
      title: '操作',
      key: 'action',
      fixed: 'right' as const,
      render: (text: string, record: Models.Group.GroupChatDetail) => (
        <Button type="link" onClick={() => showGroupMembersDrawer(record)}>
          查看群成员
        </Button>
      ),
    },
  ];

  const fetchGroupList = async ({ current, pageSize }: { current: number; pageSize: number }) => {
    const res = await GroupApi.getGroupChatList({ groupNestId: id, pageNo: current, pageSize });
    return {
      list: res.objects,
      total: res.totalCount,
    };
  };

  const { tableProps } = useAntdTable(fetchGroupList, { defaultPageSize: DEFAULT_PAGE_SIZE });

  const [groupMembersDrawerVisible, setGroupMembersDrawerVisible] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<Models.Group.GroupChatDetail | null>(null);

  const showGroupMembersDrawer = (group: Models.Group.GroupChatDetail) => {
    setSelectedGroup(group);
    setGroupMembersDrawerVisible(true);
  };

  const handleGroupMembersDrawerClose = () => {
    setGroupMembersDrawerVisible(false);
    setSelectedGroup(null);
  };

  return (
    <div>
      {selectedGroup && (
        <GroupMembersDrawer
          visible={groupMembersDrawerVisible}
          onClose={handleGroupMembersDrawerClose}
          id={selectedGroup.id}
          name={selectedGroup.name}
        />
      )}
      <Table
        {...tableProps}
        columns={columns}
        title={() => `共${tableProps.pagination.total}个群`}
        locale={{
          emptyText: <Empty text="暂无群聊" />,
        }}
        pagination={
          tableProps.pagination && tableProps.pagination.total > tableProps.pagination.pageSize
            ? tableProps.pagination
            : false
        }
        scroll={{ x: 'max-content' }}
      />
    </div>
  );
};

interface GroupMembersDrawerProps {
  visible: boolean;
  id: Id;
  name: string;
  onClose: () => void;
}
const GroupMembersDrawer: FC<GroupMembersDrawerProps> = ({ visible, onClose, id, name }) => {
  const columns = [
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
      render: () => name,
    },
    {
      title: '昵称',
      dataIndex: 'userName',
      key: 'userName',
      render: (userName: string) => {
        return <StaffTag name={userName} />;
      },
    },
    {
      title: '身份',
      dataIndex: 'role',
      key: 'role',
      align: 'right' as const,
      render: (role: string, groupChatMemberInfo: Models.Group.GroupChatMemberInfo) => {
        if (groupChatMemberInfo.isAdmin) {
          return '群管理员';
        }
        if (groupChatMemberInfo.isMaster) {
          return '群主';
        }
        return '群成员';
      },
    },
  ];

  const fetchGroupMemberList = async ({ current, pageSize }: { current: number; pageSize: number }) => {
    const res = await GroupApi.getGroupMemberList({ groupChatId: id, pageNo: current, pageSize });
    return {
      list: res.objects,
      total: res.totalCount,
    };
  };

  const { tableProps } = useAntdTable(fetchGroupMemberList, { defaultPageSize: DEFAULT_PAGE_SIZE });

  return (
    <Drawer title={name} placement="right" onClose={onClose} open={visible} width={640}>
      <p>共{tableProps.pagination.total}个成员</p>
      <Table
        columns={columns}
        {...tableProps}
        pagination={
          tableProps.pagination && tableProps.pagination.total > tableProps.pagination.pageSize
            ? tableProps.pagination
            : false
        }
        locale={{
          emptyText: <Empty text="暂无群成员" />,
        }}
      />
    </Drawer>
  );
};

export default GroupNestDetailDrawer;
