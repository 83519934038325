import { get } from './common';

export namespace CouponApi {
  // 获取优惠券活动列表
  export const getCouponActivityList = (params: {
    pageNo: number;
    pageSize: number;
    keyword?: string;
    type: 'merchant' | 'payment';
    activityStatus?: Models.Coupon.ActivityStatusEnum;
    voucherType?: Models.Coupon.VoucherTypeEnum;
  }) => {
    return get<{ hasNext: boolean; objects: Models.Coupon.CouponActivity[]; totalCount: number; hasCoupon: boolean }>(
      '/coupon_activity/list',
      {
        ...params,
      },
    );
  };

  // 同步优惠券
  export const syncCoupon = () => {
    return get<{ success: boolean }>('/coupon_activity/sync', {});
  };

  // 获取优惠券同步状态
  export const getCouponSyncStatus = () => {
    return get<{ data: { status: 'no_running' | 'running' | 'success'; syncLastTime: number } }>(
      '/coupon_activity/sync/status',
      {},
    );
  };
}
