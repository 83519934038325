import { commonUtils } from '@/helpers/commonUtils';
import { CloseCircleOutlined, DownOutlined, PlusOutlined, UpOutlined } from '@ant-design/icons'; // Import CloseCircleOutlined
import { Button } from 'antd';
import { observer } from 'mobx-react';
import { FC, useEffect, useRef, useState } from 'react';
import IconFont from '../IconFont/IconFont';
import { SimpleCoupon } from '../SelectCouponDialog';
import StaffTag from '../StaffTag/StaffTag';
import styles from './AddMemberList.module.less';

interface AddMemberListProps {
  type: 'staff' | 'groupNest' | 'coupon';
  /** 按钮文字 */
  btnText?: string;
  /** 是否隐藏按钮, 默认 false */
  hideButton?: boolean;
  /** 是否可以编辑组件 */
  disabled?: boolean;
  /** 按钮底部提示文字 */
  buttonBottomTips?: string;
  /** 是否隐藏删除按钮 */
  hideDeleteButton?: boolean;
  /** 点击添加按钮回调 */
  onAddClick?: () => void;
  /** 点击删除按钮回调 */
  onDeleteClick?: (id: Id) => void;
  /** 成员列表,通过外部传入 */
  memberList?: Models.User.MemberItem[];
  /** 群组列表,通过外部传入 */
  groupNestList?: Models.Group.GroupNestBaseInfo[];
  /** 优惠券列表,通过外部传入 */
  couponList?: SimpleCoupon[];
}

const AddMemberList: FC<AddMemberListProps> = observer((props: AddMemberListProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showExpandButton, setShowExpandButton] = useState(false);
  const listRef = useRef<HTMLUListElement>(null);

  const {
    btnText,
    disabled,
    hideButton,
    onAddClick,
    onDeleteClick,
    memberList,
    buttonBottomTips,
    type,
    groupNestList,
    couponList,
    hideDeleteButton,
  } = props;

  const renderStaffItem = (item: Models.User.MemberItem) => {
    return (
      <li key={item.id}>
        <StaffTag
          name={item.name}
          close={() => {
            if (!disabled) {
              onDeleteClick?.(item.id);
            }
          }}
        />
      </li>
    );
  };

  const renderGroupNestItem = (item: Models.Group.GroupNestBaseInfo) => {
    return (
      <li key={item.id}>
        <div className={styles.groupNestItem}>
          <IconFont type="icon-qunzu" style={{ color: '#889FE7', fontSize: 22 }} />
          <div className={styles.groupNestItemContentName}>
            <span className={styles.groupNestItemContentNameText}>{item.name}</span>
            <span className={styles.groupNestItemContentNameCount}>共{item.groupChatCount}个群</span>
          </div>
          {!hideDeleteButton && !disabled && (
            <CloseCircleOutlined className={styles.closeIcon} onClick={() => onDeleteClick?.(item.id)} />
          )}
        </div>
      </li>
    );
  };

  const renderCouponItem = (item: SimpleCoupon) => {
    return (
      <li key={item.id}>
        <div className={styles.couponItem}>
          <img className={styles.couponIcon} src={commonUtils.getStaticFile('coupon/coupon-icon.png')} />
          <span className={styles.couponName}>{item.name}</span>
          {!hideDeleteButton && !disabled && (
            <CloseCircleOutlined className={styles.closeIcon} onClick={() => onDeleteClick?.(item.id)} />
          )}
        </div>
      </li>
    );
  };
  const hasItems =
    (type === 'staff' && memberList && memberList.length > 0) ||
    (type === 'groupNest' && groupNestList && groupNestList.length > 0) ||
    (type === 'coupon' && couponList && couponList.length > 0);

  useEffect(() => {
    const checkOverflow = () => {
      if (listRef.current) {
        // 使用 scrollHeight 和 clientHeight 来判断是否溢出
        const isOverflowing = listRef.current.scrollHeight > listRef.current.clientHeight;
        setShowExpandButton(isOverflowing);
        if (!isOverflowing) {
          setIsExpanded(false);
        }
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, [memberList, groupNestList]);

  return (
    <>
      {!hideButton && (
        <>
          <Button disabled={disabled} onClick={onAddClick}>
            <PlusOutlined />
            {btnText}
          </Button>
          {buttonBottomTips && <div className={styles.buttonBottomTips}>{buttonBottomTips}</div>}
        </>
      )}
      {hasItems && (
        <ul
          ref={listRef}
          className={`${styles.addMemberList} ${isExpanded ? styles.expanded : ''}`}
          style={{ maxHeight: isExpanded ? 'none' : '60px', overflow: 'hidden' }}
        >
          {type === 'staff' && memberList?.map((item) => renderStaffItem(item))}
          {type === 'groupNest' && groupNestList?.map((item) => renderGroupNestItem(item))}
          {type === 'coupon' && couponList?.map((item) => renderCouponItem(item))}
        </ul>
      )}
      {showExpandButton && hasItems && (
        <div className={styles.expandButton}>
          <span
            style={{
              padding: '0',
              display: 'flex',
              alignItems: 'center',
              fontSize: '14px',
              color: '#8C8C8C',
              cursor: 'pointer',
            }}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? (
              <>
                收起
                <UpOutlined style={{ fontSize: '12px', marginLeft: '4px' }} />
              </>
            ) : (
              <>
                展开
                <DownOutlined style={{ fontSize: '12px', marginLeft: '4px' }} />
              </>
            )}
          </span>
        </div>
      )}
    </>
  );
});

export default AddMemberList;
