import { theme, ThemeConfig } from 'antd';
import type { AliasToken } from 'antd/es/theme/internal';

const { getDesignToken } = theme;
const defaultThemeTokenConfig: Partial<AliasToken> = {
  colorPrimary: '#507BFF',
  colorPrimaryHover: '#4F6CEF',
  colorText: '#333333',
  colorInfoBg: 'rgba(80,123,255,0.15)',
  colorInfoText: 'rgba(0,0,0,0.88)',
  colorInfoBorder: 'rgba(80,123,255,0.4)',
};

class ThemeHelper {
  init() {
    const { colorPrimary, colorPrimaryHover, colorText, colorInfoBg, colorInfoText, colorInfoBorder } = this.themeToken;
    const rootEl = document.querySelector(':root') as HTMLElement;
    rootEl.style.setProperty('--color-primary', colorPrimary!);
    rootEl.style.setProperty('--color-primary-hover', colorPrimaryHover!);
    rootEl.style.setProperty('--color-text', colorText!);
    rootEl.style.setProperty('--color-info-bg', colorInfoBg!);
    rootEl.style.setProperty('--color-info-text', colorInfoText!);
    rootEl.style.setProperty('--color-info-border', colorInfoBorder!);
  }
  get theme(): ThemeConfig {
    return {
      token: this.themeToken,
    };
  }

  get themeToken(): Partial<AliasToken> {
    const tokenConfig = defaultThemeTokenConfig;
    return getDesignToken({ token: tokenConfig });
  }
}

export const themeHelper = new ThemeHelper();
