import { User } from '@/helpers/api/user';
import { Alert, Form, message } from 'antd';
import { observer } from 'mobx-react';
import { FC } from 'react';
import AmeInput from '../AmeInput/AmeInput';
import Dialog from '../Dialog/Dialog';
import styles from './AddGroupAdminDialog.module.less';

interface AddGroupAdminDialogProps {
  visible: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const AddGroupAdminDialog: FC<AddGroupAdminDialogProps> = observer(
  ({ visible, onClose, onSuccess }: AddGroupAdminDialogProps) => {
    const [form] = Form.useForm();

    const handleOk = () => {
      form
        .validateFields()
        .then((values) => {
          User.createGroupAuthorizeUser({
            userName: values.adminName,
            loginId: values.adminAccount,
          })
            .then(() => {
              message.success('添加成功');
              onSuccess();
            })
            .catch((error) => {
              message.error(error.statusMessage);
            })
            .finally(() => {
              form.resetFields();
            });
        })
        .catch((info) => {
          console.log('验证失败:', info);
        });
    };

    return (
      <Dialog
        className={styles.addGroupAdminDialog}
        title="添加管理员"
        open={visible}
        onOk={handleOk}
        onCancel={onClose}
        okText="确认"
        cancelText="取消"
      >
        <Alert
          message="提交后需要员工完成授权才可设置为群主/群管理员，授权通知将会发送至员工「支付宝APP-最新消息」中查看"
          type="info"
          style={{
            marginBottom: 16,
          }}
          showIcon
        />
        <Form form={form} layout="horizontal">
          <Form.Item name="adminName" label="管理员姓名" rules={[{ required: true, message: '请输入管理员姓名!' }]}>
            <AmeInput placeholder="请输入管理员姓名" />
          </Form.Item>
          <Form.Item
            name="adminAccount"
            label="支付宝账号"
            rules={[
              { required: true, message: '请输入支付宝账号!' },
              { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的支付宝账号格式!' },
            ]}
          >
            <AmeInput placeholder="请输入支付宝账号" />
          </Form.Item>
        </Form>
      </Dialog>
    );
  },
);

export default AddGroupAdminDialog;
