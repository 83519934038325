import { RouterServiceTypes } from '@/services/routerService/serviceTypes'
import { MD5 } from 'crypto-js'
import numeral from 'numeral'
import path from 'path-browserify'
import { v4 as uuidV4 } from 'uuid'
import { PAGE_LINKS } from './commonConsts'

type InputValue = string | number | null | undefined

class CommonUtils {
  getOsType(): 'OSX' | 'IOS' | 'Windows' | 'Linux' | 'Android' {
    const userAgent = navigator.userAgent
    const platform = (navigator as any).userAgentData?.platform || navigator.platform
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K', 'macOS']
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
    const iosPlatforms = ['iPhone', 'iPad', 'iPod']

    if (macosPlatforms.indexOf(platform) !== -1) {
      return 'OSX'
    }
    if (iosPlatforms.indexOf(platform) !== -1) {
      return 'IOS'
    }
    if (windowsPlatforms.indexOf(platform) !== -1) {
      return 'Windows'
    }
    if (/Android/.test(userAgent)) {
      return 'Android'
    }
    if (/Linux/.test(platform)) {
      return 'Linux'
    }
    console.error('unable to detect os type, use Windows as default', platform, userAgent)
    return 'Windows'
  }

  getIsMobile() {
    return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)
  }

  /**
   * check if browser is chrome
   * 注意这个方法并不是完全可靠，在一些情况下edge会伪装自己的userAgent，使其和chrome一致，具体条件不明
   */
  isChrome() {
    const userAgent = navigator.userAgent
    const isChromiumBased = userAgent.includes('Chrome') && userAgent.includes('Safari')
    const isEdge = userAgent.includes('Edg')
    const isOpera = userAgent.includes('OPR')

    return isChromiumBased && !isEdge && !isOpera
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isPromise(obj: any): obj is Promise<unknown> {
    return (
      !!obj &&
      (typeof obj === 'object' || typeof obj === 'function') &&
      typeof obj.then === 'function'
    )
  }

  /** 按照指定 query 参数构建 url */
  genUrl(base: string, query: { [key: string]: string } = {}, encode = true): string {
    const keys = Object.keys(query)
    if (keys.length === 0) {
      return base
    }
    let url = base
    if (/:[a-zA-Z]/.test(base)) {
      // eslint-disable-next-line no-param-reassign
      // 处理 pattern 中声明的 restful 风格参数
      const patternParts = base.split('/')
      patternParts.forEach((p) => {
        if (p.startsWith(':')) {
          const name = p.slice(1)
          url = url.replace(p, query[name])
          delete query[name]
        }
      })
    }
    url += '?'
    for (const k of keys) {
      let v = query[k]
      if (!v) {
        continue
      }
      v = encode ? encodeURIComponent(v) : v
      url += `${k}=${v}&`
    }
    return url.slice(0, -1) // cut off last '&' or '?'
  }

  removeQueryFromUrl(url: string) {
    return url.split('?')[0]
  }

  genId(short = false) {
    const uuid = uuidV4()
    return short ? uuid.split('-')[0] : uuid
  }

  md5(content: string) {
    return MD5(content).toString()
  }

  onDOMReady(callback: () => void) {
    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', callback)
    } else {
      callback()
    }
  }

  /**
   * 将key字符串转换成驼峰方式命名（如 "someName"） 的字符串
   * @param key string类型
   * @param separators key分隔符 "-"中划线/"_"下划线
   */
  camelizeKey(key: string, separators: string[] = ['-', '_']): string {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const out: any = []
    let i = 0
    const separatorsSet = new Set(separators)
    while (i < key.length) {
      if (separatorsSet.has(key[i])) {
        out.push(key[i + 1].toUpperCase())
        i++
      } else {
        out.push(key[i])
      }
      i++
    }
    return out.join('')
  }

  /**
   * 将对象键值对中的 key 转换为按照驼峰方式命名的 key
   * @param obj
   */
  camelize(obj: object): { [key: string]: any } {
    if (obj === null || obj === undefined) {
      return null as any
    }
    if (obj instanceof Array) {
      return obj.map((item) => {
        return this.camelize(item)
      })
    }
    if (typeof obj === 'object') {
      const out: any = {}
      // eslint-disable-next-line no-restricted-syntax
      for (const key in obj as any) {
        const v = (obj as any)[key]
        out[this.camelizeKey(key)] = this.camelize(v)
      }
      return out
    }
    return obj
  }

  /**
   * 将key字符串转换成下划线方式命名 (如 "some_name") 的字符串
   * @param key 对象字符串
   * @param ignoreFirst 是否忽略第一个大写字母，如果忽略，会将其当成小写字母处理
   */
  underlizeKey(key: string, ignoreFirst = false): string {
    const out: string[] = []
    let i = 0
    const lowerCasedStr: string = key.toString().toLowerCase()
    while (i < key.length) {
      if (key[i] !== lowerCasedStr[i]) {
        if (!ignoreFirst || i !== 0) {
          out.push('_')
          out.push(lowerCasedStr[i])
          i++
          continue
        }
      }
      out.push(key[i].toLocaleLowerCase())
      i++
    }
    return out.join('')
  }

  /**
   * 将对象键值对中的 key 转换为按照下划线方式命名的 key
   * @param obj 需要转换的对象
   */
  underlize(obj: object): {} {
    if (obj === null || obj === undefined) {
      return null as any
    }
    if (obj instanceof Array) {
      return obj.map((item) => {
        return this.underlize(item)
      })
    }
    if (typeof obj === 'object') {
      const out: any = {}
      // eslint-disable-next-line no-restricted-syntax
      for (const key in obj as any) {
        const v = (obj as any)[key]
        const underlizeKey = this.underlizeKey(key)
        const value = this.underlize(v)
        if (value !== null && value !== undefined) {
          out[underlizeKey] = value
        }
      }
      return out
    }
    return obj
  }

  /**
   * 将字符串转为首字母大写
   * @param str
   * @returns
   */
  capitalize(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  copyToClipboard(text: string) {
    const input = document.createElement('textarea')
    input.id = 'copy-to-clipboard'
    const container = document.body
    container.appendChild(input)
    input.oncopy = (e) => {
      e.stopPropagation()
    }
    input.value = text
    input.select()
    document.execCommand('copy')
    container.removeChild(input)
  }

  getOffsetOfPageTop(element: HTMLElement) {
    return (
      document.documentElement.clientHeight -
      (element.getBoundingClientRect().top - document.documentElement.getBoundingClientRect().top)
    )
  }

  async delay(time: number) {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve()
      }, time)
    })
  }


  formatNumber(number: InputValue) {
    if (number === undefined || number === null) {
      return '-'
    }

    const format = numeral(number).format('0,0.0')

    const key = '.0'
    const isInteger = format.includes(key)

    return isInteger ? format.replace(key, '') : format
  }

  selectFile(
    callBack: (fileList: File[]) => void,
    options?: {
      accept?: string[]
      multiple?: boolean
    }
  ) {
    const { multiple = false, accept = [] } = options || {}
    const input = document.createElement('input')

    input.type = 'file'
    if (multiple) {
      input.multiple = true
    }
    input.style.display = 'none'
    input.accept = accept
      .map((el) => {
        if (el === '.txt') {
          return 'text/plain'
        }
        return el
      })
      .join(', ')

    document.body.appendChild(input)

    const handler = () => {
      if (input.files) {
        callBack(Array.from(input.files))
      }
      input.removeEventListener('change', handler)
      input.remove()
    }

    input.addEventListener('change', handler)
    input.click()
  }

  async downloadFile(url: string, fileName: string) {
    const link = document.createElement('a')
    link.href = url
    link.download = fileName
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  getQueryFromUrl(key: string, url: string) {
    const urlObj = new URL(url)
    return urlObj.searchParams.get(key)
  }

  convertUnixToHms(num: number) {
    const h = num < 3600 ? 14 : 11
    return new Date(num * 1000).toISOString().substring(h, 19).toString()
  }

  getRealRandomNumber(range = 10) {
    const arr = new Uint32Array(1)
    window.crypto.getRandomValues(arr)
    return arr[0] % range
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getPageParams<P extends keyof RouterServiceTypes.PageParams>(pageName: P) {
    return this.getUrlParams() as RouterServiceTypes.PageParams[P]
  }

  getPageUrl<P extends keyof RouterServiceTypes.PageParams>(
    pageName: P, params: RouterServiceTypes.PageParams[P]
  ) {
    return this.genUrl(PAGE_LINKS[pageName], params)
  }


  /**
   * 获取 url 中的参数, 以 dict 形式返回
   * @param pattern url 范式，比如 /file/:id，会自动识别其中的参数并抽取到结果 dict 中
   * @param url url，默认为当前页面 url
   */
  getUrlParams(
    pattern: string | null = null,
    url = window.location.href
  ): { [key: string]: string } {
    let u: URL
    if (url.startsWith('http:') || url.startsWith('https:')) {
      u = new URL(url)
    } else {
      u = new URL(window.location.origin + url)
    }
    const res: { [key: string]: string } = {}
    if (pattern) {
      // 处理 pattern 中声明的 restful 风格参数
      const patternParts = pattern.split('/')
      const pathParts = u.pathname.split('/')
      patternParts.forEach((p, i) => {
        if (p.startsWith(':')) {
          const name = p.slice(1)
          res[name] = pathParts[i]
        }
      })
    }
    const params = new URLSearchParams(u.search)
    for (const k of params.keys()) {
      // params.get 会自动处理 decodeURLComponent，不需要手动调用
      const v = params.get(k)
      if (v) {
        res[k] = v
      }
    }
    return res
  }

  isInputNode(el: HTMLElement): boolean {
    if (el instanceof HTMLInputElement) {
      return true
    }
    if (el instanceof HTMLTextAreaElement) {
      return true
    }
    if (el.isContentEditable) {
      return true
    }
    return false
  }

  formatErrorMsg(err: any, defaultMsg = '未知错误，请重试或联系客服') {
    if (err instanceof Error) {
      return err.message
    }
    if (typeof err === 'string') {
      return err
    }
    if (typeof err === 'object') {
      return err.statusMessage || err.message || defaultMsg
    }
    return defaultMsg
  }

  formatFileSize(bytes: number, decimalPoint = 2) {
    if (bytes === 0) return '0 Bytes'
    const k = 1000,
      dm = decimalPoint || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k))
    // eslint-disable-next-line no-restricted-properties
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  getRandomIntForRange(min: number, max: number) {
    const _min = Math.ceil(min)
    const _max = Math.floor(max)
    return Math.floor(Math.random() * (_max - _min) + _min)
  }

  getTextWidth(text: string, style: Partial<CSSStyleDeclaration>) {
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    if (!context) {
      return 0
    }
    const defaultFontFamily =
      style.fontFamily ||
      " -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, 'Noto Sans JP', 'Noto Sans KR', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'"
    const fontWeight = style.fontWeight || 'normal'
    context.font = `${fontWeight} ${style.fontSize} ${defaultFontFamily}`
    const metrics = context.measureText(text)

    return metrics.width
  }

  /**
   * @author mpc
   * 将项目中原本声明的非正式的 locale key 转换为国际通用的 locale key
   * 如：zh_CN -> zh-Hans
   */
  standardizeLocale(locale: string): string {
    switch (locale) {
      case 'pt_BR':
        return 'pt'
      case 'pt_PT':
        return 'pt-PT'
      case 'zh_TW':
      case 'zh_HK':
        return 'zh-Hant'
      case 'zh':
      case 'zh_CN':
        return 'zh-Hans'
      case 'es_419':
        return 'es-419'
      default:
        return locale
    }
  }

  /**
   * 生成URL链接
   * @param path URL中的路径
   * @param queryArgs URL中 “?” 后面的参数
   */
  genApiUrl(path: string, queryArgs: object, underlizeKeys = false): string {
    if (!queryArgs) {
      queryArgs = {}
    }
    if (underlizeKeys) {
      queryArgs = commonUtils.underlize(queryArgs)
    }
    const args: string[] = []
    for (const key in queryArgs as any) {
      const v = (queryArgs as any)[key]
      if (v !== null) {
        args.push(`${key}=${v}`)
      }
    }
    if (args.length === 0) {
      return path
    }
    return `${path}?${args.join('&')}`
  }


  transformNumberToKilo(num: number, fixed = 2) {
    if (num < 1000) {
      return num
    }
    return `${(num / 1000).toFixed(fixed)}k`
  }


  public genUrlWithSearch(base: string) {
    return `${base}${window.location.search}`
  }

  public genUrlWithParam(base: string) {
    return `${base}&${window.location.search.substring(1)}`
  }

  public getStaticFile(name: string) {
    const oemFilePath = require(`@/assets/${name}`)
    return oemFilePath
  }

  /**
 * 校验上传的文件类型
 * @date 2021-12-02
 * @param {File} {name:fileName}:File
 * @returns {String}
 */
  public checkUploadFileType({ type }: File): string {
    // console.log('当前文件类型：', type)
    const mineList = [
      'image/png', // png
      'image/jpeg', // jpg/jpeg
      'image/jpg', // jpg/jpeg
      'image/gif', // gif
      'application/vnd.ms-excel', // xls
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
      'video/mp4', // mp4
      'video/quicktime', // .mov 格式，兼容ios上传视频
      'application/pdf', // pdf
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
      'application/msword', // doc
      'text/plain', // txt
      'application/vnd.openxmlformats-officedocument.presentationml.presentation', // pptx
      'application/vnd.ms-powerpoint', // ppt
      'text/csv', // csv,
      'application/wps-writer', // doc
      '', // mac上有些文件错误
      'application/wps-office.xlsx', // 开发电脑上传时的类型
      'application/zip', // .zip
      'application/x-rar-compressed', // .rar
      'application/x-zip-compressed',
      'application/octet-stream', // win的rar文件表现为此类型,.dmg
      'application/vnd.rar',
      'application/vnd.xmind.workbook', // .xmind
      'image/vnd.rn-realpix', // .rp
      'application/x-msdownload', // .exe
      'audio/x-pn-realaudio', // .ram
      'audio/amr-wb', // .amr
    ]
    return mineList.includes(type) ? '' : '文件格式错误，请重新上传'
  }

  public getRandomOssUploadUrl(data: {
    corpId?: string
    module?: PostData.FileUploadModule
    userId: Id
    fileName: string
  }) {
    // eslint-disable-next-line prefer-const
    let { corpId, fileName, module, userId } = data
    // 去掉fileName中会出错的字符
    fileName = fileName.replace(/[+#%?/&=]/g, '')
    const ext = path.extname(fileName)
    const name = path.basename(fileName, ext)
    const random = this.genSessionId()
    const newFilename = `${name}_${random}${ext}`
    const arr = [corpId, module, userId]
    return `/${arr.filter((a) => !!a).join('/')}/${newFilename.replace(/ /g, '_')}`
  }

  /**
 * 生成随机字符串
 */
  public genSessionId(): string {
    return Date.now().toString(36) + parseInt(`${Math.random() * 100000}`).toString(36)
  }

  /**
 * 删除给定url的参数
 */
  public removeUrlArgs(url: string, args: string[]) {
    const [baseUrl] = url.split('#')
    if (baseUrl.indexOf('?') === -1) {
      return url
    }
    const urlObj = new URL(url)
    const queryArr = urlObj.search.substring(1).split('&')
    const parts: string[] = []
    queryArr.forEach((q) => {
      const [k] = q.split('=')
      if (!args.some((a) => k === a) && parts.indexOf(q) === -1) {
        parts.push(q)
      }
    })
    const { protocol, host, pathname, hash } = urlObj
    const base = `${protocol}//${host}${pathname}`
    if (parts.length > 0) {
      return `${base}?${parts.join('&')}${hash}`
    }
    return base + hash
  }

}

export const commonUtils = new CommonUtils()
