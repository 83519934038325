import { Tooltip, TooltipProps } from 'antd';
import { observer } from 'mobx-react';
import { FC } from 'react';
import styles from './AmeToolTip.module.less';

type AmeToolTipProps = TooltipProps;

const AmeToolTip: FC<AmeToolTipProps> = observer((props: AmeToolTipProps) => {
  const renderTitle = () => {
    if (typeof props.title === 'function') {
      return props.title();
    }
    return <span style={{ color: '#333' }}>{props.title}</span>;
  };

  return (
    <Tooltip {...props} title={renderTitle()} color="#FFF" className={`${props.className} ${styles.ameToolTip}`}>
      {props.children}
    </Tooltip>
  );
});

export default AmeToolTip;
