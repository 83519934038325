import { uiController } from '@/controllers/uiController';
import { commonUtils } from '@/helpers/commonUtils';
import { uploadService } from '@/helpers/uploadHelper';
import { useUserService } from '@/services/userService';
import { Alert, Button, Upload, UploadProps, message } from 'antd';
import { RcFile } from 'antd/es/upload';
import { observer } from 'mobx-react';
import { FC, useEffect, useState } from 'react';
import Dialog from '../Dialog/Dialog';
import styles from './UploadExcelDialog.module.less';

const { Dragger } = Upload;

export interface UploadExcelDialogProps {
  open: boolean;
  data?: {
    type: PostData.FileUploadModule;
    onOk?: (fileList: Models.Upload.File[]) => void;
    onCancel?: () => void;
  };
}

const UploadExcelDialog: FC<UploadExcelDialogProps> = observer((props: UploadExcelDialogProps) => {
  const [visible, setVisible] = useState(props.open);
  const { userInfo } = useUserService();
  const [fileList, setFileList] = useState<Models.Upload.File[]>([]);
  const [accept, setAccept] = useState<string>('.xlsx,.xls');
  const [multiple, setMultiple] = useState<boolean>(false);
  const [maxCount, setMaxCount] = useState<number>(1);
  const [maxSize, setMaxSize] = useState<number>(10);
  const handleOk = () => {
    props.data?.onOk?.(fileList);
    handleClose();
  };

  const handleClose = () => {
    setVisible(false);
    setTimeout(() => {
      uiController.uploadExcelDialogProps.open = false;
    }, 200);
  };

  const uploadProps: UploadProps = {
    name: 'file',
    multiple,
    accept,
    maxCount,
    beforeUpload: (file: RcFile) => {
      if (file.size / 1024 / 1024 > maxSize) {
        message.error(`${file.name} 文件大小超过 ${maxSize}M`);
        return Upload.LIST_IGNORE;
      }
      return true;
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        setFileList(
          info.fileList.map((file) => ({
            fileUrl: file.response.fileUrl,
            fileId: file.response.fileId,
            fileName: file.name,
          })),
        );
      }
      if (status === 'done') {
        message.success(`${info.file.name} 文件上传成功。`);
      } else if (status === 'error') {
        message.error(`${info.file.name} 文件上传失败。`);
      }
    },
    customRequest({ file, onSuccess }) {
      uploadService
        .uploadFile({
          file: file as File,
          module: 'invite_group',
          userId: userInfo?.id || '',
        })
        .then((res) => {
          onSuccess?.({ fileUrl: res.cdnUrl, fileId: res.fileId, fileName: (file as File).name });
        })
        .catch((error) => {
          message.error(error.statusMessage);
        });
    },
  };

  const [title, setTitle] = useState('');
  const [descriptionText, setDescriptionText] = useState('');
  const [downloadTemplateUrl, setDownloadTemplateUrl] = useState('');
  useEffect(() => {
    if (props.data?.type === 'invite_group') {
      setTitle('导入创群设置');
      setDescriptionText(
        '仅支持 xlsx、xls 格式，模板中表头不可更改；受支付宝限制，群组创建上限为200个，建议单次批量建群不超过50个。',
      );
      setDownloadTemplateUrl(
        'https://cdn.weibanzhushou.com/weiban-prod/%E6%94%AF%E4%BB%98%E5%AE%9D-%E8%87%AA%E5%8A%A8%E5%BB%BA%E7%BE%A4-%E5%A4%9A%E7%BE%A4%E5%88%9B%E5%BB%BA%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
      );
      setAccept('.xlsx,.xls');
      setMultiple(false);
      setMaxCount(1);
      setMaxSize(10);
    }
  }, [props.data?.type]);

  return (
    <Dialog
      title={title}
      open={visible}
      onOk={handleOk}
      onCancel={handleClose}
      okText="下一步"
      cancelText="取消"
      footer={[
        <Button key="cancel" onClick={handleClose}>
          取消
        </Button>,
        <Button key="next" type="primary" onClick={handleOk}>
          下一步
        </Button>,
      ]}
      className={styles['import-excel-dialog']}
    >
      <Alert
        className={styles.description}
        message={
          <>
            {descriptionText}
            <a href={downloadTemplateUrl} style={{ marginLeft: 8 }}>
              下载模板
            </a>
          </>
        }
        type="info"
        showIcon
      />
      <Dragger {...uploadProps}>
        <p className={styles.uploadIcon}>
          <img src={commonUtils.getStaticFile('upload-excel-dialog/upload-icon.png')} alt="" />
        </p>
        <p className={styles.uploadText}>点击或将文件拖拽到这里上传</p>
        <p className={styles.uploadHint}>仅支持 xlsx、xls 格式，请将大小控制在 {maxSize}M 以内</p>
      </Dragger>
    </Dialog>
  );
});

export default UploadExcelDialog;
