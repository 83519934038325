import { get, post } from './common';

export namespace CommonApi {
  // 获取功能配置状态
  export const getFeatureConfigStatus = (params: { featureName: Models.featureType }) => {
    return get<{ fcStatus: 'enable' | 'disable' }>('/corp/feature_config/status', params);
  };

  // 开启功能配置
  export const openFeatureConfig = (params: { featureName: Models.featureType }) => {
    return post('/corp/feature_config/open', {}, params);
  };

  // 关闭功能配置
  export const closeFeatureConfig = (params: { featureName: Models.featureType }) => {
    return post('/corp/feature_config/close', {}, params);
  };

  // 获取链接详情
  export const getLinkDetail = (params: { url: string }) => {
    return post<{
      desc: string;
      imgUrl: string;
      link: string;
      title: string;
    }>('/common/parse_link_article', {}, params);
  };

  /** 通用导出接口v2 */
  export function getExportUrlV2(params: {
    format: 'excel' | 'zip';
    type: PostData.ExportExcelTaskTypeV2;
    filterData: ExportService.ExportServiceFilterData[PostData.ExportExcelTaskTypeV2];
    /** 某个模块有多种类型数据导出时加以区分，没有时传'-' */
    taskId?: string;
    /** 区分多个模块下的导出 */
    source?: string;
  }) {
    return post('/common/data_process/export', { taskId: params.taskId, type: params.type }, params);
  }

  /** 获取导出任务状态 */
  export function getExportTaskStatus(params: {
    taskId?: string;
    type?: PostData.ExportExcelTaskTypeV2;
    download?: boolean;
  }) {
    return get<{
      data:{
        status: 'no_running' | 'success' | 'error' | 'running';
        url?: string;
        exportTime?:string
      }
    }>('/common/data_process/export_status', params);
  }
}
