import { commonUtils } from '@/helpers/commonUtils';
import { observer } from 'mobx-react';
import { FC } from 'react';

interface EmptyProps {
  text?: string;
}

const Empty: FC<EmptyProps> = observer((props: EmptyProps) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px',
      }}
    >
      <img src={commonUtils.getStaticFile('common/empty.png')} style={{ width: 100, marginBottom: 16 }} />
      <p style={{ color: 'rgba(16,16,16,0.65)', fontSize: 14 }}>{props.text || '暂无数据'}</p>
    </div>
  );
});

export default Empty;
