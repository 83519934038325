export const PAGE_LINKS = {
  index: '/',
  home: '/home',
  login: '/login',
  inviteGroupManage: '/invite_group/manage',
  inviteGroupCreate: '/invite_group/create',
  // 门店活码
  areaScanCodeManagePage: '/area_code/manage',
  areaScanCodeCreatePage: '/area_code/create',
  // 入群有礼
  joinGroupGiftManagePage: '/join_group_gift/manage',
  joinGroupGiftCreatePage: '/join_group_gift/create',
  // 批量群发
  autoSendManagePage: '/auto_send/manage',
  autoSendCreatePage: '/auto_send/create',

  // 发布群公告
  groupNoticeManagePage: '/group_notice/manage',
  groupNoticeCreatePage: '/group_notice/create',

  // 群列表
  groupListManagePage: '/group_list/manage',
  groupListCreatePage: '/group_list/create',
  // 优惠券管理
  couponManagePage: '/coupon/manage',
  couponCreatePage: '/coupon/create',
  // 账号管理
  staffManagePage: '/staff/manage',
}

export const DEFAULT_PAGE_SIZE = 20;
