import { commonUtils } from '@/helpers/commonUtils';
import { EyeOutlined } from '@ant-design/icons';
import { Image } from 'antd';
import { FC, useEffect, useRef } from 'react';
import styles from './MessageCardItem.module.less';

interface MessageContentProps {
  msg: Models.AutoSend.MessageAttachment;
}

const MessageCardItem: FC<MessageContentProps> = ({ msg }) => {
  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (textRef.current && msg.textMsgContent) {
      let content = msg.textMsgContent.text.replace(/\n/g, '<br>');
      if (msg.textMsgContent.atAll) {
        content = `@所有人 ${content}`;
      }
      textRef.current.innerHTML = content;
    }
  }, [msg.textMsgContent, msg.msgType]);

  return (
    <>
      {/* 处理文字 */}
      {msg.msgType === 'text' && msg.textMsgContent?.text && (
        <div ref={textRef} className={`${styles.messageCardItem} ${styles.text}`}></div>
      )}

      {/* 处理图片 */}
      {msg.msgType === 'image' && msg.imageMsgContent && (
        <div className={`${styles.messageCardItem} ${styles.image}`}>
          <Image
            src={msg.imageMsgContent.imageUrl}
            preview={{
              mask: (
                <>
                  <EyeOutlined style={{ marginRight: 4 }} />
                  <span>预览</span>
                </>
              ),
            }}
          />
        </div>
      )}

      {/* 处理链接 */}
      {msg.msgType === 'image-text' && msg.imageTextMsgContent && (
        <div
          className={`${styles.messageCardItem} ${styles.link}`}
          onClick={() => {
            msg.imageTextMsgContent?.url && window.open(msg.imageTextMsgContent.url, '_blank');
          }}
        >
          <p className={styles['title']}>{msg.imageTextMsgContent.title}</p>
          <div className={styles['link-inner']}>
            <img src={msg.imageTextMsgContent.imageUrl} />
            <p className={styles['desc']}>{msg.imageTextMsgContent.desc}</p>
          </div>
        </div>
      )}

      {/* 处理小程序 */}
      {msg.msgType === 'tiny' && msg.tinyAppMsgContent && (
        <div className={`${styles.messageCardItem} ${styles.tiny}`}>
          <p className={styles.title}>
            <span className={styles.name}>{msg.tinyAppMsgContent.title}</span>
            <span className={styles.desc}>{msg.tinyAppMsgContent.desc}</span>
          </p>
          <img className={styles.image} src={msg.tinyAppMsgContent.imageUrl} />
        </div>
      )}
      {/* 处理优惠券 */}
      {msg.msgType === 'coupon' &&
        msg.couponMsgContent &&
        msg.couponMsgContent.coupon &&
        msg.couponMsgContent.couponActivity && (
          <div
            className={`${styles.messageCardItem} ${styles.coupon} ${msg.couponMsgContent.moreSetting ? styles.couponWithMoreSetting : ''}`}
          >
            <p className={styles.title}>
              <img src={commonUtils.getStaticFile('phone-preview-box/gift.png')} />
              <span>{msg.couponMsgContent.coupon.title}</span>
            </p>
            <div className={styles.couponInner}>
              {msg.couponMsgContent.moreSetting && msg.couponMsgContent.coupon.imageUrl && (
                <img className={styles.couponImage} src={msg.couponMsgContent.coupon.imageUrl} alt="" />
              )}
              <div className={styles.couponContent}>
                <div className={styles.couponLeft}>
                  <span className={styles.discount}>5</span>
                  <span className={styles.unit}>元</span>
                </div>
                <div className={styles.couponRight}>
                  <div className={styles.couponRightInner}>
                    <p className={styles.couponTitle}>{msg.couponMsgContent.couponActivity.voucherName}</p>
                    <p className={styles.couponDesc}>{msg.couponMsgContent.couponActivity.voucherDescription}</p>
                  </div>
                  <div className={styles.couponRightButton}>领取</div>
                </div>
              </div>
              {msg.couponMsgContent.moreSetting && msg.couponMsgContent.coupon.longIntroduceText && (
                <div className={styles.couponLongIntroduce}>{msg.couponMsgContent.coupon.longIntroduceText}</div>
              )}
            </div>
          </div>
        )}
    </>
  );
};

export default MessageCardItem;
