import { SearchOutlined } from '@ant-design/icons';
import { Input, InputProps } from 'antd';
import { observer } from 'mobx-react';
import { FC, useState } from 'react';
import styles from './AmeInput.module.less';

interface AmeInputProps extends InputProps {
  inputType?: 'normal' | 'search';
}

const AmeInput: FC<AmeInputProps> = observer((props: AmeInputProps) => {
  const { inputType = 'normal' } = props;
  const inputProps = {
    ...props,
  };
  // 去掉inputType属性
  delete inputProps.inputType;
  const [showSuffix, setShowSuffix] = useState<boolean>(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (props.allowClear) {
      setShowSuffix(e.target.value.length === 0);
    }
    // 调用原始的onChange事件处理函数
    props.onChange?.(e);
  };

  return (
    <Input
      {...inputProps}
      className={`${props.className} ${styles.ameInput}`}
      onChange={handleChange}
      autoComplete="off"
      suffix={showSuffix && inputType === 'search' && <SearchOutlined />}
    />
  );
});

export default AmeInput;
