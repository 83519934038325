import { uiController } from '@/controllers/uiController';
import { AutoSendApi } from '@/helpers/api/autoSendApi';
import { autoSendMsgHelper } from '@/helpers/autoSendMsgHelper';
import { commonUtils } from '@/helpers/commonUtils';
import { dayjsHelper } from '@/helpers/dayjsHelper';
import { renderStatus } from '@/pages/AutoSendManagePage';
import { Descriptions, Drawer } from 'antd';
import { observer } from 'mobx-react';
import { FC, useEffect, useState } from 'react';
import AddMemberList from '../AddMemberList/AddMemberList';
import MessageCardItem from '../MessageCardItem/MessageCardItem';
import StaffTag from '../StaffTag/StaffTag';
import styles from './AutoSendDetailDrawer.module.less';

export type AutoSendDetailDrawerProps = {
  open: boolean;
  data: {
    task?: Models.AutoSend.AutoSendTask;
  };
};

const AutoSendDetailDrawer: FC<AutoSendDetailDrawerProps> = observer((props: AutoSendDetailDrawerProps) => {
  const [open, setOpen] = useState<boolean>(true);
  const [detail, setDetail] = useState<Models.AutoSend.AutoSendTaskDetail>();
  const {
    data: { task },
  } = props;

  useEffect(() => {
    if (task) {
      const fetchDetail = async () => {
        const detail = await AutoSendApi.getAutoSendTaskDetail({ id: task.id });
        setDetail(detail.data);
      };
      fetchDetail();
    }
  }, [task]);

  // eslint-disable-next-line complexity
  const renderMessageItem = (message: Models.AutoSend.AutoSendTaskDetail['subMissions'][number], index: number) => {
    const msg = autoSendMsgHelper.transformAttachment(message);
    return (
      <div key={commonUtils.genId()} className={styles.messageItem}>
        <span className={styles.messageItemLabel}>消息{index + 1}:</span>
        {msg && <MessageCardItem msg={msg} />}
      </div>
    );
  };

  return (
    <Drawer
      title="发送详情"
      placement="right"
      closable={true}
      width={560}
      onClose={() => {
        setOpen(false);
        setTimeout(() => {
          uiController.autoSendDetailDrawerProps.open = false;
        }, 200);
      }}
      open={open}
    >
      {detail && (
        <div className={styles.autoSendDetailDrawer}>
          <Descriptions title="基本信息" column={1}>
            <Descriptions.Item label="群发名称">{detail.name}</Descriptions.Item>
            <Descriptions.Item label="发送状态">{renderStatus(detail.status)}</Descriptions.Item>
            <Descriptions.Item label="群发时间">
              {dayjsHelper.formatTime(detail.sendTime * 1000, 'YYYY/MM/DD HH:mm:ss')}
            </Descriptions.Item>
            <Descriptions.Item label="创建时间">
              {dayjsHelper.formatTime(detail.createdAt * 1000, 'YYYY/MM/DD HH:mm:ss')}
            </Descriptions.Item>
            <Descriptions.Item label="创建人">
              <StaffTag name={detail.creatorInfo.name} />
            </Descriptions.Item>
            <Descriptions.Item label="群发范围">
              <div className={styles.groupNestInfoBox}>
                <p>共{detail.groupNestInfo.length}个群组</p>
                <AddMemberList hideButton hideDeleteButton groupNestList={detail.groupNestInfo} type={'groupNest'} />
              </div>
            </Descriptions.Item>
            <Descriptions.Item label="群发内容">
              <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
                共{detail.subMissions.length}条消息
                <div className={styles.messageContent}>
                  {detail.subMissions.map((subMission, index) => (
                    <div key={index} className={styles.messageItem}>
                      {subMission.msgData.msgType === 'TEXT' && (
                        <div className={styles.messageItem}>
                          <span className={styles.messageItemLabel}>消息{index + 1}:</span>
                          <span>{subMission.msgData.textMsgContent?.content}</span>
                        </div>
                      )}
                      {subMission.msgData.msgType !== 'TEXT' && renderMessageItem(subMission, index)}
                    </div>
                  ))}
                </div>
              </div>
            </Descriptions.Item>
          </Descriptions>
        </div>
      )}
    </Drawer>
  );
});

export default AutoSendDetailDrawer;
