import { get, post } from './common';

export namespace User {
  // 获取群组授权用户列表
  export function getGroupAuthorizeList(params: { pageSize: number; pageNo: number; keyword?: string }) {
    return get<{ objects: Models.User.UserDetail[]; totalCount: number }>('/group/authorize/user_list', params);
  }

  /***
   * 创建群组授权用户
   */
  export function createGroupAuthorizeUser(params: { userName: string; loginId: Id }) {
    return post<{ id: number }>('/group/authorize/user_add', {}, { ...params });
  }

  // 获取员工列表
  export function getStaffLoginList(params: { username: string; password: string }) {
    return post<{ staffList: Models.User.Staff[]; key: string }>('/auth/staff_list', {}, params);
  }

  // 员工登录
  export function staffLogin(params: { key: string; staffId: number }) {
    return post('/auth/staff_login', {}, params);
  }

  // 获取当前员工信息
  export function getStaffMe() {
    return get<Models.User.Staff>('/staff/me');
  }

  // 刷新员工列表
  export function refreshStaffList() {
    return get('/group/authorize/sync', {});
  }

  // 删除群组授权用户
  export function deleteGroupAuthorize(id: Id) {
    return post('/group/authorize/user_delete', {}, { id });
  }

  // 登出
  export function logout() {
    return get('/auth/logout', {});
  }
}
