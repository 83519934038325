import { Modal, ModalProps } from 'antd';
import { observer } from 'mobx-react';
import { FC } from 'react';
import styles from './Dialog.module.less';

type DialogProps = ModalProps;

const Dialog: FC<DialogProps> = observer((props: DialogProps) => {
  return (
    <Modal {...props} className={`${styles.dialog} ${props.className}`}>
      {props.children}
    </Modal>
  );
});

export default Dialog;
