import { commonUtils } from '@/helpers/commonUtils';
import { envHelper } from '@/helpers/envHelper';

export type MethodType = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
interface ReqInfo {
  url: string;
  method: MethodType;
  headers: Record<string, string>;
  bodyArgs: APIBodyArgCollection;
  camelize: boolean;
  controller?: AbortController;
}

type APIQueryArgCollection = ApiTypes.ApiQueryArgCollection;
type APIBodyArgCollection = ApiTypes.ApiBodyArgCollection;

export class ApiProxy {
  public async sendRequest(
    method: MethodType,
    pathPrefix: string,
    urlArgs: APIQueryArgCollection = {},
    bodyArgs: APIBodyArgCollection = {},
    camelize = true,
    controller?: AbortController,
  ): Promise<any> {
    let path = '';
    if (path.indexOf('http') === 0) {
      path = commonUtils.underlizeKey(path);
    } else {
      path = `${envHelper.apiBasePath}${commonUtils.underlizeKey(pathPrefix)}`;
    }
    const url = commonUtils.genApiUrl(
      path,
      commonUtils.underlize(this.filterUrlArgs(urlArgs)),
    );
    const headers = await this.getAuthHeaders();
    return this.handleSendRequest({
      url,
      headers,
      method,
      bodyArgs: this.filterUrlArgs(bodyArgs),
      camelize,
      controller,
    });
  }

  private filterUrlArgs(urlArgs?: APIQueryArgCollection): APIQueryArgCollection {
    if (!urlArgs) {
      return {}
    }
    const args: APIQueryArgCollection = {}
    // eslint-disable-next-line no-restricted-syntax
    for (const key in urlArgs) {
      if (urlArgs[key] !== undefined) {
        args[key] = urlArgs[key]
      }
    }
    return args
  }


  private handleSendRequest(reqInfo: ReqInfo): Promise<any> {
    const body: RequestInit = {
      method: reqInfo.method,
      headers: reqInfo.headers,
      signal: reqInfo.controller?.signal,
    };
    if (reqInfo.method === 'POST') {
      body.body = JSON.stringify(commonUtils.underlize(reqInfo.bodyArgs || {}));
      if (!body.headers) {
        body.headers = {};
      }
      (body.headers as Record<string, string>)['Content-Type'] = 'application/json';
    }
    const req = fetch(reqInfo.url, body);
    return req
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          return res.json();
        }
        throw commonUtils.camelize(res);
      })
      .then((res) => {
        if (res.success) {
          if (reqInfo.camelize) {
            return commonUtils.camelize(res);
          }
          return res;
        }
        throw commonUtils.camelize(res);
      });
  }

  private async getAuthHeaders(): Promise<{ [key: string]: string }> {
    return {};
  }
}
